import * as apiLib from './api';

export const list = async ({ shopId, startDt, endDt }) => {

    try {
      const data = {
        shopId,
        startDt,
        endDt,
      };
  
      const result = await apiLib.fetchStoreApi('/pos/sales-analysis/list', data);
      if (result.resultFlag) {
        return result.salesAnalysisList;
      } else {
        throw Object.assign(new Error('sales-analysis list error'), { status: 401 });
      }
    } catch (err) {
        console.log(err);
      throw err;
    }
  };


  
export const logAdd = async ({ shopId,logDt,categoryInfoList,goodsInfoList,deviceId,deviceNm,deviceSalesList,typeInfoList,typeQtyInfoList,orderTimeList,orderHList,payTypeInfoList,payTypeQtyInfoList}) => {

    try {
      const data = {
        shopId,
        logDt,
        categoryInfoList,
        goodsInfoList,
        deviceId,
        deviceNm,
        deviceSalesList,
        typeInfoList,
        typeQtyInfoList,
        payTypeInfoList,
        payTypeQtyInfoList,
        orderTimeList,
        orderHList,

        // managerId
        
      };
  
      const result = await apiLib.fetchStoreApi('/pos/sales-analysis/log-add', data);
      if (result.resultFlag) {
        return result.salesAnalysisLog;
      } else {
        throw Object.assign(new Error('sales-analysis log-add error'), { status: 401 });
      }
    } catch (err) {
        console.log(err);
      throw err;
    }
  };


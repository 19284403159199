import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { shopAction } from '../../modules/shopSlice';

const ShopInfo = ({ deviceId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(shopAction.shopInfo({ deviceId }));
  }, [dispatch, deviceId]);

  return <></>
};

export default React.memo(ShopInfo);
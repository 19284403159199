import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface OrderPayload {
  orderId: string;
  orderStartDt: string;
  orderEndDt: string;
  billType: string;
}

interface OrderEndInfo {
  shopId: string;
  logDt: string;
  totalAmt: string;
  totalEnr: string;
  totalReturnAmt: string;
  totalCnt: string;
  managerId: string;
}

interface FilterInfo {
  billType: string;
  paymentSearch: any;
}

interface OrderState {
  orderList: Array<OrderInfo>;
  filterInfo: FilterInfo;
  selectedOrderId: string;
  payloadOrderDt: any;
  actionResult: string;
  loadingOrderIdList: string[];
  isLoading: boolean;
  error: string | null;
  orderEndInfo: OrderEndInfo;
  orderAnalysisInfo: orderAnalysisInfo;
}

const orderInitialState: OrderState = {
  orderList: [],
  currentOpenOrderList: [],
  selectedOrderId: '',
  filterInfo: { paymentSearch: { type: 'all', value: '', billType: 'ALL' } },
  payloadOrderDt: { orderStartDt: '', orderEndDt: '' },
  actionResult: '',
  loadingOrderIdList: [],
  isLoading: false,
  error: null,
  orderEndInfo: {
    shopId: '',
    logDt: '',
    totalAmt: '',
    totalEnr: '',
    totalReturnAmt: '',
    totalCnt: '',
    managerId: '',
    newFlag: '',
    beforeDaily: {},
  },
  orderAnalysisInfo: { shopId: '', logDt: '', categoryInfoList: {}, goodsInfoList: {}, newFlag: '', beforeDaily: {} },
};

const reducers = {
  initOrderState: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.filterInfo = { paymentSearch: { type: 'all', value: '', billType: 'ALL' } };
    state.payloadOrderDt = { orderStartDt: '', orderEndDt: '' };
    state.loadingOrderIdList = [];
    state.selectedOrderId = '';
    state.actionResult = '';
    state.isLoading = false;
    state.error = null;
    state.orderEndInfo = {
      shopId: '',
      logDt: '',
      totalAmt: '',
      totalEnr: '',
      totalReturnAmt: '',
      totalCnt: '',
      managerId: '',
      newFlag: '',
      beforeDaily: {},
    };
    state.orderAnalysisInfo = {
      shopId: '',
      logDt: '',
      categoryInfoList: {},
      goodsInfoList: {},
      newFlag: '',
      beforeDaily: {},
    };
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
  orderList: (state: OrderState, { payload }: PayloadAction<OrderPayload>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  orderListSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.orderList = payload.orderList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  orderListFailure: (state: OrderState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  orderOpenList: (state: OrderState, { payload }: PayloadAction<OrderPayload>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  orderOpenListSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.orderList = payload.orderList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  orderOpenListFailure: (state: OrderState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  currentOpenOrderList: (state: OrderState, { payload }: PayloadAction<OrderPayload>) => {
    state.actionResult = 'OPEN_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  currentOpenOrderListSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.currentOpenOrderList = payload.currentOpenOrderList;
    state.actionResult = 'OPEN_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  currentOpenOrderListFailure: (state: OrderState, action: PayloadAction<string>) => {
    state.actionResult = 'OPEN_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  updateOrder: (state: OrderState, { payload }: PayloadAction<OrderPayload>) => {
    state.actionResult = 'UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  updateOrderSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.actionResult = 'UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  updateOrderFailure: (state: OrderState, action: PayloadAction<string>) => {
    state.actionResult = 'UPDATE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  regOrder: (state: OrderState, { payload }: PayloadAction<OrderPayload>) => {
    state.actionResult = 'REG_REQ';
    if (!state.loadingOrderIdList.find(orderId => orderId === payload.orderId)) {
      state.loadingOrderIdList.push(payload.orderId);
    }
    state.isLoading = true;
    state.error = null;
  },
  regOrderSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.actionResult = 'REG_OK';
    const orderIndex = state.currentOpenOrderList.findIndex(order => order.orderId === payload.orderId);
    state.currentOpenOrderList[orderIndex].billType = 'REG';

    const index = state.loadingOrderIdList.findIndex(orderId => orderId === payload.orderId);
    if (index > -1) {
      state.loadingOrderIdList.splice(index, 1);
    }
    state.isLoading = false;
    state.error = null;
  },
  regOrderFailure: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.actionResult = 'REG_ERR';
    const index = state.loadingOrderIdList.findIndex(orderId => orderId === payload.orderId);
    if (index > -1) {
      state.loadingOrderIdList.splice(index, 1);
    }
    state.isLoading = false;
    state.error = payload.error;
  },
  regOrderRobot: (state: OrderState, { payload }: PayloadAction<OrderPayload>) => {
    state.actionResult = 'REG_REQ';
    if (!state.loadingOrderIdList.find(orderId => orderId === payload.orderId)) {
      state.loadingOrderIdList.push(payload.orderId);
    }
    state.isLoading = true;
    state.error = null;
  },
  regOrderRobotSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.actionResult = 'REG_OK';
    const orderIndex = state.currentOpenOrderList.findIndex(order => order.orderId === payload.orderId);
    let robotDeliveryInfo = state.currentOpenOrderList[orderIndex].robotDeliveryInfo ?? {};
    robotDeliveryInfo.robotName = payload.robotName;
    state.currentOpenOrderList[orderIndex].billType = 'REG';
    state.currentOpenOrderList[orderIndex].robotDeliveryInfo = robotDeliveryInfo;

    const index = state.loadingOrderIdList.findIndex(orderId => orderId === payload.orderId);
    if (index > -1) {
      state.loadingOrderIdList.splice(index, 1);
    }
    state.isLoading = false;
    state.error = null;
  },
  regOrderRobotFailure: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.actionResult = 'REG_ROBOT_ERR';
    const orderIndex = state.currentOpenOrderList.findIndex(order => order.orderId === payload.orderId);
    let robotDeliveryInfo = state.currentOpenOrderList[orderIndex].robotDeliveryInfo ?? {};
    let errorname = 'ROBOT_ERROR';
    if (payload.errorname === -78100) {
      errorname = 'NOT_RESERVED_ORDER';
    } else if (payload.errorname === -78300) {
      errorname = 'NO_ALLOCATABLE_ROBOT';
    } else if (payload.errorname === -78001) {
      errorname = 'SERVER_ERROR';
    }
    robotDeliveryInfo.reasonType = errorname;
    state.currentOpenOrderList[orderIndex].robotDeliveryInfo = robotDeliveryInfo;
    state.currentOpenOrderList[orderIndex].robotDeliveryStatus = 'canceled';
    const index = state.loadingOrderIdList.findIndex(orderId => orderId === payload.orderId);
    if (index > -1) {
      state.loadingOrderIdList.splice(index, 1);
    }
    state.isLoading = false;
    state.error = payload.error;
  },
  takeOrder: (state: OrderState, { payload }: PayloadAction<OrderPayload>) => {
    state.actionResult = 'TAKE_REQ';
    if (!state.loadingOrderIdList.find(orderId => orderId === payload.orderId)) {
      state.loadingOrderIdList.push(payload.orderId);
    }
    state.isLoading = true;
    state.error = null;
  },
  takeOrderSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.actionResult = 'TAKE_OK';
    const orderIndex = state.currentOpenOrderList.findIndex(order => order.orderId === payload.orderId);
    state.currentOpenOrderList[orderIndex].billType = 'TAKE_REQ';
    const index = state.loadingOrderIdList.findIndex(orderId => orderId === payload.orderId);
    if (index > -1) {
      state.loadingOrderIdList.splice(index, 1);
    }
    state.isLoading = false;
    state.error = null;
  },
  takeOrderFailure: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.actionResult = 'TAKE_ERR';
    const index = state.loadingOrderIdList.findIndex(orderId => orderId === payload.orderId);
    if (index > -1) {
      state.loadingOrderIdList.splice(index, 1);
    }
    state.isLoading = false;
    state.error = payload.error;
  },
  takeOrderRobot: (state: OrderState, { payload }: PayloadAction<OrderPayload>) => {
    state.actionResult = 'TAKE_REQ';
    if (!state.loadingOrderIdList.find(orderId => orderId === payload.orderId)) {
      state.loadingOrderIdList.push(payload.orderId);
    }
    state.isLoading = true;
    state.error = null;
  },
  takeOrderRobotSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.actionResult = 'TAKE_OK';
    const orderIndex = state.currentOpenOrderList.findIndex(order => order.orderId === payload.orderId);
    state.currentOpenOrderList[orderIndex].billType = 'TAKE_REQ';
    const index = state.loadingOrderIdList.findIndex(orderId => orderId === payload.orderId);
    if (index > -1) {
      state.loadingOrderIdList.splice(index, 1);
    }
    state.isLoading = false;
    state.error = null;
  },
  takeOrderRobotFailure: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.actionResult = 'TAKE_ERR';
    const index = state.loadingOrderIdList.findIndex(orderId => orderId === payload.orderId);
    if (index > -1) {
      state.loadingOrderIdList.splice(index, 1);
    }
    state.isLoading = false;
    state.error = payload.error;
  },
  saleOrderList: (state: OrderState, { payload }: PayloadAction<OrderPayload>) => {
    state.actionResult = 'SALE_REQ';
    for (const orderId of payload.orderIdList) {
      if (!state.loadingOrderIdList.find(loadingOrderId => loadingOrderId === orderId)) {
        state.loadingOrderIdList.push(orderId);
      }
    }
    state.isLoading = true;
    state.error = null;
  },
  saleOrderListSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.actionResult = 'SALE_OK';
    for (const orderId of payload.orderIdList) {
      const orderIndex = state.currentOpenOrderList.findIndex(order => order.orderId === orderId);

      if (orderIndex > -1) {
        if (
          state.currentOpenOrderList[orderIndex]?.takeType === 'ROBOT' &&
          (state.currentOpenOrderList[orderIndex]?.robotDeliveryStatus === 'canceled' ||
            state.currentOpenOrderList[orderIndex]?.robotDeliveryStatus === 'aborted' ||
            state.currentOpenOrderList[orderIndex].robotDeliveryInfo?.reasonType === 'NOSHOW')
        ) {
          console.log('robot pass');
        } else {
          state.currentOpenOrderList[orderIndex].billType = 'SALE';
        }
      }

      const index = state.loadingOrderIdList.findIndex(loadingOrderId => loadingOrderId === orderId);
      if (index > -1) {
        state.loadingOrderIdList?.splice(index, 1);
      }
    }
    state.isLoading = false;
    state.error = null;
  },
  saleOrderListFailure: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.actionResult = 'SALE_ERR';
    for (const orderId of payload.orderIdList) {
      const index = state.loadingOrderIdList.findIndex(loadingOrderId => loadingOrderId === orderId);
      if (index > -1) {
        state.loadingOrderIdList.splice(index, 1);
      }
    }
    state.isLoading = false;
    state.error = payload.error;
  },
  cancelOrderList: (state: OrderState, { payload }: PayloadAction<OrderPayload>) => {
    state.actionResult = 'CANCEL_REQ';
    for (const orderId of payload.orderIdList) {
      if (!state.loadingOrderIdList.find(loadingOrderId => loadingOrderId === orderId)) {
        state.loadingOrderIdList.push(orderId);
      }
    }
    state.isLoading = true;
    state.error = null;
  },
  cancelOrderListSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.actionResult = 'CANCEL_OK';
    for (const orderId of payload.orderIdList) {
      const orderIndex = state.currentOpenOrderList.findIndex(order => order.orderId === orderId);
      state.currentOpenOrderList[orderIndex].billType = 'CANCEL';
      const index = state.loadingOrderIdList.findIndex(loadingOrderId => loadingOrderId === orderId);
      if (index > -1) {
        state.loadingOrderIdList.splice(index, 1);
      }
    }
    state.isLoading = false;
    state.error = null;
  },
  cancelOrderListFailure: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.actionResult = 'CANCEL_ERR';
    for (const orderId of payload.orderIdList) {
      const index = state.loadingOrderIdList.findIndex(loadingOrderId => loadingOrderId === orderId);
      if (index > -1) {
        state.loadingOrderIdList.splice(index, 1);
      }
    }
    state.isLoading = false;
    state.error = payload.error;
  },
  returnOrder: (state: OrderState, { payload }: PayloadAction<OrderPayload>) => {
    state.actionResult = 'RETURN_REQ';
    if (!state.loadingOrderIdList.find(orderId => orderId === payload.orderId)) {
      state.loadingOrderIdList.push(payload.orderId);
    }
    state.isLoading = true;
    state.error = null;
  },
  returnOrderSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.selectedOrderId = payload.newOrder.orderId;
    state.actionResult = 'RETURN_OK';
    state.isLoading = false;
    state.error = null;
  },
  returnOrderFailure: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.actionResult = 'RETURN_ERR';
    state.isLoading = false;
    state.error = payload.error;
  },
  returnReceiveSocketOrderListUpdate: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    const index = state.orderList.findIndex(order => order.orderId === payload.orderId);
    state.orderList[index] = { ...state.orderList[index], ...payload.updateInfo };
    state.orderList.push(payload.newOrder);
    state.isLoading = false;
    state.error = null;
  },
  selectOrder: (state: OrderState, { payload }: PayloadAction<string>) => {
    state.selectedOrderId = payload.orderId;
  },
  orderFilter: (state: OrderState, { payload: { key, value } }: PayloadAction<OrderState>) => {
    state.filterInfo[key] = value;
  },
  setPayloadOrderDt: (state: OrderState, { payload: { orderStartDt, orderEndDt } }: PayloadAction<OrderState>) => {
    state.payloadOrderDt = {
      orderStartDt,
      orderEndDt,
    };
  },
  orderEndInfo: (state: OrderEndInfo, { payload }: PayloadAction<OrderState>) => {
    state.orderEndInfo = payload.orderEndInfo;
  },
  orderAnalysisInfo: (state: orderAnalysisInfo, { payload }: PayloadAction<OrderState>) => {
    state.orderAnalysisInfo = payload.orderAnalysisInfo;
    state.actionResult = 'INFO_OK';
  },
};

const slice = createSlice({
  name: 'order',
  initialState: orderInitialState,
  reducers: reducers,
});

const selectOrderList = createDraftSafeSelector(
  (state: OrderState) => state.orderList,
  orderList => orderList,
);

const selectCurrentOpenOrderList = createDraftSafeSelector(
  (state: OrderState) => state.currentOpenOrderList,
  currentOpenOrderList => currentOpenOrderList,
);

const selectOrderFilter = createDraftSafeSelector(
  (state: OrderState) => state.filterInfo,
  filterInfo => filterInfo,
);

const selectSelectedOrder = createDraftSafeSelector(
  (state: OrderState) => state.orderList,
  (state: OrderState) => state.selectedOrderId,

  (orderList, selectedOrderId) => {
    if (orderList.length > 0) {
      return orderList.find(order => order.orderId === selectedOrderId);
    } else {
      return undefined;
    }
  },
);

const selectCurrentOpenOrder = createDraftSafeSelector(
  (state: OrderState) => state.currentOpenOrderList,
  (state: OrderState) => state.selectedOrderId,

  (currentOpenOrderList, selectedOrderId) => {
    if (currentOpenOrderList.length > 0) {
      return currentOpenOrderList.find(order => order.orderId === selectedOrderId);
    } else {
      return undefined;
    }
  },
);

const selectPayloadOrderDt = createDraftSafeSelector(
  (state: OrderState) => state.payloadOrderDt,
  payloadOrderDt => payloadOrderDt,
);

const selectFilteredOrderList = createDraftSafeSelector(
  (state: any) => state.orderList,
  (state: any) => state.filterInfo,

  (orderList, filterInfo) => {
    // 정렬
    let sortOrderList = [...orderList].sort((l, r) => (l.regDt === r.regDt ? 0 : l.regDt > r.regDt ? -1 : 1));

    // 결제내역에서 검색창 필터링
    let paymentSearchOrderList = sortOrderList;
    if (filterInfo.paymentSearch.billType !== 'ALL') {
      paymentSearchOrderList = paymentSearchOrderList.filter(
        order => order.billType === filterInfo.paymentSearch.billType,
      );
    }

    if (filterInfo.paymentSearch.value) {
      if (filterInfo.paymentSearch.type === 'waitNo') {
        paymentSearchOrderList = paymentSearchOrderList.filter(
          order => order.waitNo === Number(filterInfo.paymentSearch.value),
        );
      } else if (filterInfo.paymentSearch.type === 'customerNm') {
        paymentSearchOrderList = paymentSearchOrderList.filter(
          order =>
            order.paymentList[0]?.customerNm?.toLowerCase().indexOf(filterInfo.paymentSearch.value?.toLowerCase()) >= 0,
        );
      } else {
        paymentSearchOrderList = paymentSearchOrderList.filter(
          order =>
            order.waitNo === Number(filterInfo.paymentSearch.value) ||
            order.paymentList[0]?.customerNm?.toLowerCase().indexOf(filterInfo.paymentSearch.value?.toLowerCase()) >= 0,
        );
      }
    }
    return paymentSearchOrderList;
  },
);

// billType에 따른 orderList
const selectFilteredTypeCurrentOpenOrderList = createDraftSafeSelector(
  (state: OrderState) => state.currentOpenOrderList,
  currentOpenOrderList => {
    const waitOrderList = currentOpenOrderList.filter(order => order.billType === 'WAIT');
    const regOrderList = currentOpenOrderList.filter(order => order.billType === 'REG');
    const takeReqOrderList = currentOpenOrderList.filter(order => order.billType === 'TAKE_REQ');
    const saleOrderList = currentOpenOrderList.filter(order => order.billType === 'SALE');
    const cancelOrderList = currentOpenOrderList.filter(order => order.billType === 'CANCEL');
    const returnOrderList = currentOpenOrderList.filter(order => order.billType === 'RETURN');
    return { waitOrderList, regOrderList, takeReqOrderList, saleOrderList, cancelOrderList, returnOrderList };
  },
);

const selectStatus = createDraftSafeSelector(
  (state: OrderState) => state.actionResult,
  (state: OrderState) => state.isLoading,
  (state: OrderState) => state.error,
  (state: OrderState) => state.payloadOrderDt,
  (actionResult, isLoading, error, payloadOrderDt) => ({ actionResult, isLoading, error, payloadOrderDt }),
);

const selectLoadingOrderIdList = createDraftSafeSelector(
  (state: OrderState) => state.loadingOrderIdList,
  loadingOrderIdList => loadingOrderIdList,
);

const selectOrderEndInfo = createDraftSafeSelector(
  (state: OrderState) => state.orderEndInfo,
  orderEndInfo => orderEndInfo,
);

const selecctOrderAnalysisInfo = createDraftSafeSelector(
  (state: OrderState) => state.orderAnalysisInfo,
  orderAnalysisInfo => orderAnalysisInfo,
);

export const orderSelector = {
  orderList: state => selectOrderList(state[ORDER]),
  currentOpenOrderList: state => selectCurrentOpenOrderList(state[ORDER]),
  orderFilter: state => selectOrderFilter(state[ORDER]),
  filteredOrderList: state => selectFilteredOrderList(state[ORDER]),
  filteredTypeCurrentOpenOrderList: state => selectFilteredTypeCurrentOpenOrderList(state[ORDER]),
  selectedOrder: state => selectSelectedOrder(state[ORDER]),
  selectedCurrentOpenOrder: state => selectCurrentOpenOrder(state[ORDER]),
  payloadOrderDt: state => selectPayloadOrderDt(state[ORDER]),
  status: state => selectStatus(state[ORDER]),
  loadingOrderIdList: state => selectLoadingOrderIdList(state[ORDER]),
  orderEndInfo: state => selectOrderEndInfo(state[ORDER]),
  orderAnalysisInfo: state => selecctOrderAnalysisInfo(state[ORDER]),
};

export const ORDER = slice.name;
export const orderReducer = slice.reducer;
export const orderAction = slice.actions;

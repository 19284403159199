import { combineReducers } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import { AGENT, agentReducer } from './agentSlice';
import { PLAYER, playerReducer } from './playerSlice';
import { ORDER, orderReducer } from './orderSlice';
import { GOODS, goodsReducer } from './goodsSlice';
import { OPENLOG, openLogReducer } from './openLogSlice';
import { SHOP, shopReducer } from './shopSlice';
import { REPORT, reportReducer } from './reportSlice';
import { SALESANALYSISLOG, salesAnalysisLogReducer} from './salesAnalysisLogSlice';
import { SALESDAILYLOG, salesDailyLogReducer } from './salesDailyLogSlice';
import { SALESMONTHLOG, salesMonthLogReducer } from './salesMonthLogSlice';


import agentSaga from './agentSaga';
import playerSaga from './playerSaga';
import orderSaga from './orderSaga';
import goodsSaga from './goodsSaga';
import openLogSaga from './openLogSaga';
import shopSaga from './shopSaga';
import salesAnalysisLogSaga from './salesAnalysisLogSaga';
import salesDailyLogSaga from './salesDailyLogSaga';
import salesMonthLogSaga from './salesMonthLogSaga';
import { SETTING, settingReducer } from './settingSlice';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    [AGENT]: agentReducer,
    [PLAYER]: playerReducer,
    [ORDER]: orderReducer,
    [GOODS]: goodsReducer,
    [OPENLOG]: openLogReducer,
    [SHOP]: shopReducer,
    [REPORT]: reportReducer,
    [SALESANALYSISLOG]:salesAnalysisLogReducer,
    [SALESDAILYLOG]:salesDailyLogReducer,
    [SALESMONTHLOG]:salesMonthLogReducer,
    [SETTING]: settingReducer,
  });

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
  yield all([
    agentSaga(),
    playerSaga(),
    orderSaga(),
    goodsSaga(),
    openLogSaga(),
    shopSaga(),
    salesAnalysisLogSaga(),
    salesDailyLogSaga(),
    salesMonthLogSaga(),
  ]);
}

export const history = createBrowserHistory();

export default function createStore() {
  const store = configureStore({
    reducer: createRootReducer(history),
    devTools: true,
    middleware: [routerMiddleware(history), sagaMiddleware],
  });

  sagaMiddleware.run(rootSaga);

  return store;
}

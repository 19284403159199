import {
    createSlice,
    createDraftSafeSelector,
    PayloadAction,
  } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

interface SalesSelectPayload{
    shopId : string;
    startDt: string;
    endDt: string;
}

interface SalesInsertPayload{

  shopId : string;         
  logDt : string;          
  totalAmt : string;       
  totalEnr : string;    
  totalReturnAmt : string; 
  totalCnt : string;       
  year : string;           
  month : string;          
}

interface SalesMonthInfo {
  logId : string;            // PK          
  shopId : string;           // shopID         
  logDt : string;            // YYYYMM       
  totalAmt : string;         // 매출        
  totalEnr : string;      // 할인 금액          
  totalReturnAmt : string;   // 활불 금액
  totalCnt : string;         // 결제 건수
  businessDay : string;       // 영업일 수
  year : string;             // 연       
  month : string;            // 월
}

interface FilterInfo{
  startDt: string;
  endDt: string;
}

interface SalesMonthState{
  SalesMonthInfo : SalesMonthInfo;
  SalesMonthList : Array<SalesMonthInfo>;
  filterInfo : FilterInfo;
  actionResult : string;
  isLoading: boolean;
  error: string | null;
}

const salesMonthLogInitialState : SalesMonthState = {
  salesMonthInfo : {logId : '', shopId :'', logDt : '', totalAmt : '', totalEnr : '', totalReturnAmt : '', totalCnt : '', businessDay : '', year : '', month : ''},
  salesMonthList : [],
  filterInfo : {startDt:'',endDt : ''},
  actionResult : '',
  isLoading: false,
  error: null,
}

const reducers = {
  initSalesMonthLog: (state: SalesMonthState) => {
    state.salesMonthList = [];
    state.filterInfo = { startDt: '', endDt: ''};
  },
  salesMonthLogList :(state : SalesMonthState, {payload} : PayloadAction<SalesSelectPayload>) => {
    state.filterInfo.startDt = payload.startDt;
    state.filterInfo.endDt = payload.endDt;
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },

  salesMonthLogListSuccess: (state: SalesMonthState, { payload }: PayloadAction<SalesMonthState>) => {
    state.salesMonthList = payload.salesMonthList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  salesMonthLogListFailure: (state: SalesMonthState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  salesMonthLogAdd  :(state : SalesMonthState, {payload} : PayloadAction<SalesInsertPayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = null;
  }, 
  salesMonthLogAddSuccess: (state: SalesMonthState, { payload }: PayloadAction<SalesMonthState>) => {
    state.salesMonthInfo = payload.salesMonthInfo;
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  salesMonthLogAddFailure: (state: SalesMonthState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },

}


const slice = createSlice({
  name: 'salesMonthLog',
  initialState : salesMonthLogInitialState,
  reducers: reducers,
});

const selectSalesMonthLogList = createDraftSafeSelector(
  (state : SalesMonthState) => state.salesMonthList,
  salesMonthList => salesMonthList,
);

const selectSalesMonthLogFilter = createDraftSafeSelector(
  (state : SalesMonthState) => state.filterInfo,
  filterInfo => filterInfo,
);   


const selectStatus = createDraftSafeSelector(
  (state: OpenLogState) => state.actionResult,
  (state: OpenLogState) => state.isLoading,
  (state: OpenLogState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);


export const salesMonthLogSelector  ={
  salesMonthLogList  : state =>   selectSalesMonthLogList(state[SALESMONTHLOG]),
  salesMonthLogFilter : state => selectSalesMonthLogFilter(state[SALESMONTHLOG]),
  status : state => selectStatus(state[SALESMONTHLOG]),
}

export const SALESMONTHLOG = slice.name;
export const salesMonthLogReducer = slice.reducer;
export const salesMonthLogAction = slice.actions;

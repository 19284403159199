import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { orderAction } from '../../modules/orderSlice';
import { shopAction } from '../../modules/shopSlice';
import { goodsAction } from '../../modules/goodsSlice';

const Component = ({
  spaceId,
  placeId,
  brandId,
  shopId,
  deviceId,
  useNewOrderAlarmYn,
  usePickUpAlarmYn,
  time
}) => {
  const dispatch = useDispatch();

  const _useNewOrderAlarmYn = useRef(useNewOrderAlarmYn);
  const _usePickUpAlarmYn = useRef(usePickUpAlarmYn);

  const posWebSocket = useRef(null);

  useEffect(() => {
    _useNewOrderAlarmYn.current = useNewOrderAlarmYn;
    _usePickUpAlarmYn.current = usePickUpAlarmYn;
  }, [useNewOrderAlarmYn, usePickUpAlarmYn]);

  const orderAlarm = useCallback(() => {
    if (window.ReactNativeWebView && _useNewOrderAlarmYn.current === 'Y') {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: 'REQ_BELL_SOUND_PLAY' }),
      );
    }
  }, []);

  const pickupAlarm = useCallback(() => {
    if (window.ReactNativeWebView && _usePickUpAlarmYn.current === 'Y') {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: 'REQ_PICKUP_BELL_SOUND_PLAY' }),
      );
    }
  }, []);

  const wsConnect = useCallback(
    ({ spaceId, placeId, brandId, shopId, deviceId }) => {
      const ws = new WebSocket(
        'wss://4xwb2bwqy7.execute-api.ap-northeast-2.amazonaws.com/production?' +
          'spaceId=' +
          spaceId +
          '&placeId=' +
          placeId +
          '&brandId=' +
          brandId +
          '&shopId=' +
          shopId +
          '&deviceId=' +
          deviceId +
          '&actionType=ELIGA-STORE-POS',
      );

      ws.onopen = () => {
        console.log(dayjs().format('YYYYMMDDHHmmss'), 'connected', deviceId);
      };

      ws.onclose = (e) => {
        console.log(dayjs().format('YYYYMMDDHHmmss'), 'disconnected', `code: ${e.code}`, `reason: ${e.reason}`);
      };

      ws.onerror = err => {
        console.log(dayjs().format('YYYYMMDDHHmmss'), err);
      };

      ws.onmessage = async event => {
        try {
          console.log(dayjs().format('YYYYMMDDHHmmss'), event.data);

          const recvData = JSON.parse(event.data.toString());
          if (recvData.type === 'NEW-ORDER') {
            dispatch(goodsAction.goodsList({ brandId, shopId }));
            dispatch(
              orderAction.currentOpenOrderList({
                shopId,
              }),
            );
            // const orderId = recvData.orderId;
            orderAlarm();
          } else if (recvData.type === 'REG-ORDER') {
            dispatch(
              orderAction.currentOpenOrderList({
                shopId,
              }),
            );
            // const orderId = recvData.orderId;
          } else if (recvData.type === 'TAKE-ORDER') {
            dispatch(
              orderAction.currentOpenOrderList({
                shopId,
              }),
            );
            // const orderId = recvData.orderId;
            // const customerId = recvData.customerId;
            pickupAlarm();
          } else if (recvData.type === 'SALE-ORDER') {
            dispatch(
              orderAction.currentOpenOrderList({
                shopId,
              }),
            );
            // const orderId = recvData.orderId;
          } else if (recvData.type === 'CANCEL-ORDER') {
            dispatch(
              orderAction.currentOpenOrderList({
                shopId,
              }),
            );
            dispatch(
              orderAction.returnReceiveSocketOrderListUpdate({
                orderId: recvData.orderId,
                updateInfo: recvData.updateInfo,
                newOrder: recvData.newOrder,
              }),
            );
          } else if (recvData.type === 'RETURN-ORDER') {
            dispatch(
              orderAction.currentOpenOrderList({
                shopId,
              }),
            );
            dispatch(
              orderAction.returnReceiveSocketOrderListUpdate({
                orderId: recvData.orderId,
                updateInfo: recvData.updateInfo,
                newOrder: recvData.newOrder,
              }),
            )
          } else if (recvData.type === 'SHOP-UPDATE') {
            dispatch(shopAction.shopInfo({ deviceId }));
          }
          // else if (recvData.type === 'GOODS-UPDATE') {
          //   dispatch(goodsAction.goodsList({ brandId, shopId }));
          // }
        } catch (err) {
          console.log(err);
        }
      };

      return ws;
    },
    [dispatch, orderAlarm, pickupAlarm],
  );

  useEffect(() => {
    if (posWebSocket.current) {
      if (posWebSocket.current.readyState === WebSocket.CLOSED) {
        posWebSocket.current = wsConnect({ spaceId, placeId, brandId, shopId, deviceId });
      }
    } else {
      posWebSocket.current = wsConnect({ spaceId, placeId, brandId, shopId, deviceId });
    }
  }, [wsConnect, time, spaceId, placeId, brandId, shopId, deviceId]);

  useEffect(() => {
    return () => {
      posWebSocket.current?.close(3001, 'WebSocket Component is Unmount');
    }
  }, []);

  return <></>;
};

export default React.memo(Component);

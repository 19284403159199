import React from 'react';
import { Reset } from 'styled-reset';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

import Routes from './Routes';
import { injectStyle } from 'react-toastify/dist/inject-style';

import 'react-datepicker/dist/react-datepicker.css';

const theme = {
  white: '#fff',
  black: '#000',
  grayBackground: '#dfdfdf',
  disabled: '#f7f7f7',
  gray100: '#f1f1f1',
  gray200: '#eee',
  gray300: '#ccc',
  gray400: '#aaa',
  gray500: '#999',
  gray600: '#777',
  gray700: '#555',
  gray800: '#333',
  gray900: '#111',
  blue: '#41a1ea',
  indigo: '#6F97FE',
  purple: '#6b5eae',
  pink: '#ff679b',
  red: '#f05b5b',
  orange: '#fd7e14',
  yellow: '#ffbc00',
  green: '#1dc961',
  teal: '#02a8b5',
  cyan: '#39afd1',
  bodyBg: '#f4f5f6',
  button: {
    white: '#ffffff',
    black: '#555555',
    blue: '#6F97FE',
    green: '#1DC961',
    red: '#FF3F3F',
    gray: '#777', // 모달 취소 버튼 에러 색상 임시 설정 todo 색상변경
    gray999: '#999',
    mint: '#00C1DC',
  },
};

const GlobalStyle = createGlobalStyle`
  html {
    width: 100%;
    height: 100%;
  }

  body {
    width: 100%;
    height: 100%;
    font-family: 'Roboto', 'Noto Sans KR', sans-serif !important;
    font-size: 0.8rem;
    box-sizing: border-box;

    #root {
      width: 100%;
      height: 100%;
    }
    &.cursor-none {
      cursor: none;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    cursor: pointer;
    border: none;
    &:focus {
      outline: none;
    }
  }

  button, input, textarea {
    font: inherit;
  } 

  * {
    box-sizing: inherit;

    /* 드래그 방지 css */
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    user-select:none;

    /* 터치 음영 숨김 */
    -webkit-tap-highlight-color:rgba(255,255,255,0);

    /* long touch 비활성 */
    -webkit-touch-callout: none;
  }
`;

function App() {
  injectStyle();

  return (
    <ThemeProvider theme={theme}>
      <Reset />
      <GlobalStyle />
      <Routes />
    </ThemeProvider>
  );
}

export default App;

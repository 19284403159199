import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';
import dayjs from 'dayjs';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';

import ShopInfoBackground from './ShopInfo';
import WebSocketBackground from './WebSocket';
import OpenInfoBackground from './OpenSocket';
import AlertNewOrderBackground from './AlertNewOrder';
import SettingBackground from './Setting';

import { agentSelector, agentAction } from '../../modules/agentSlice';
import { shopAction, shopSelector } from '../../modules/shopSlice';
import { settingSelector } from '../../modules/settingSlice';
import { orderAction } from '../../modules/orderSlice';
import { isMobile } from 'react-device-detect';

const Background = props => {
  const dispatch = useDispatch();

  const licenseInfo = useSelector(agentSelector.licenseInfo);
  const shopInfo = useSelector(shopSelector.shopInfo);
  const { actionResult: shopActionResult } = useSelector(shopSelector.status);
  const deviceInfo = useSelector(agentSelector.deviceInfo);
  const agentStatus = useSelector(agentSelector.status);
  const { useNewOrderAlarmYn, usePickUpAlarmYn, useNoCheckOrderAlarmYn } =
    useSelector(settingSelector.alramInfo);

  const [time, setTime] = useState('');

  const backgroundEl = useRef(null);
  const manageDt = useRef('');
  const scrshotDt = useRef('');
  const onceCommandDt = useRef(dayjs().format('YYYY-MM-DD HH:mm:ss'));

  const keyPress = useCallback(e => {
    if (e.keyCode === 32) {
      // 스페이스바
      backgroundEl.current.requestFullscreen();
    }
  }, []);

  useEffect(() => {
    const _handleMessage = e => {
      let message = {};
      try {
        message = JSON.parse(e.data);
      } catch (err) {
        return;
      }

      if (message.type === 'RES_SCREEN_CAPTURE') {
        const deviceToken = licenseInfo.deviceToken;
        const deviceId = deviceInfo.deviceId;
        const base64Data = message.data;
        dispatch(
          agentAction.scrshotUpload({ deviceToken, deviceId, base64Data }),
        );
      } else if (message.type === 'RES_DEVICE_INFO') {
        const resDeviceInfo = message.deviceInfo;
        dispatch(
          agentAction.deviceManage({
            deviceToken: licenseInfo.deviceToken,
            deviceInfo: resDeviceInfo,
          }),
        );
      }
    };

    window.document.addEventListener('message', _handleMessage, true);

    return () => {
      window.document.removeEventListener('message', _handleMessage, true);
    };
  }, [dispatch, licenseInfo, deviceInfo]);

  useEffect(() => {
    if (shopActionResult === 'INFO_OK') {
      dispatch(
        orderAction.currentOpenOrderList({
          shopId: shopInfo.shopId,
        }),
      );
      dispatch(shopAction.actionResultClear());
    }
  }, [dispatch, shopActionResult, shopInfo?.shopId]);

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  useEffect(() => {
    const deviceId = localStorage.getItem('deviceId') || '';
    const licenseKey = localStorage.getItem('licenseKey') || '';
    const machineId = localStorage.getItem('machineId') || '';
    dispatch(agentAction.licenseCheck({ deviceId, licenseKey, machineId }));
  }, [dispatch]);

  useEffect(() => {
    const params = qs.parse(props.search, {
      ignoreQueryPrefix: true,
    });

    if (!params.proxy || params.proxy === '') {
      return;
    }

    const proxyData = JSON.parse(sessionStorage.getItem(params.proxy) || null);

    if (!proxyData || !proxyData.type || proxyData.type === '') {
      return;
    }

    if (proxyData.type === 'licenseCheck') {
      const deviceId = localStorage.getItem('deviceId') || '';
      const licenseKey = localStorage.getItem('licenseKey') || '';
      const machineId = localStorage.getItem('machineId') || '';
      dispatch(agentAction.licenseCheck({ deviceId, licenseKey, machineId }));
    }

    return () => {};
  }, [dispatch, props]);

  const Monitoring = useCallback(() => {
    setTime(dayjs().format('YYYY-MM-DD HH:mm:ss'));
    if (!deviceInfo.deviceId) {
      return;
    }

    let agentPeriod = 60;
    if (deviceInfo.agentPeriod) {
      agentPeriod = deviceInfo.agentPeriod < 60 ? 60 : deviceInfo.agentPeriod;
    }

    let scrshotPeriod = 300;
    if (deviceInfo.scrshotPeriod) {
      scrshotPeriod =
        deviceInfo.scrshotPeriod < 60 ? 60 : deviceInfo.scrshotPeriod;
    }

    if (
      manageDt.current === '' ||
      dayjs(manageDt.current).isBefore(dayjs().subtract(agentPeriod, 'seconds'))
    ) {
      manageDt.current = dayjs().toISOString();

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: 'REQ_DEVICE_INFO' }),
        );
      } else {
        dispatch(
          agentAction.deviceManage({ deviceToken: licenseInfo.deviceToken }),
        );
      }
    }

    if (
      scrshotDt.current === '' ||
      dayjs(scrshotDt.current).isBefore(
        dayjs().subtract(scrshotPeriod, 'seconds'),
      )
    ) {
      scrshotDt.current = dayjs().toISOString();

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: 'REQ_SCREEN_CAPTURE' }),
        );
      }
    }

    const shutdownTime = deviceInfo.shutdownTime;
    if (shutdownTime && shutdownTime !== '') {
      const shutdownTimeDt = dayjs()
        .hour(shutdownTime.split(':')[0])
        .minute(shutdownTime.split(':')[1])
        .set('second', 0)
        .set('millisecond', 0);
      if (
        shutdownTimeDt.isAfter(dayjs().subtract(300, 'seconds')) &&
        shutdownTimeDt.isBefore(dayjs())
      ) {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ type: 'REQ_SHUTDOWN' }),
          );
        }
      }
    }

    if (
      deviceInfo.weekends &&
      deviceInfo.weekends.indexOf(dayjs().format('ddd').toUpperCase()) >= 0
    ) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: 'REQ_SHUTDOWN' }),
        );
      }
    }

    if (
      deviceInfo.caseByWeekDays &&
      deviceInfo.caseByWeekDays.indexOf(dayjs().format('ddd').toUpperCase()) >=
        0
    ) {
      const todayWeek = dayjs().format('ddd').toUpperCase();
      const weekList = deviceInfo.caseByWeekDays.split(',');
      const week = weekList?.filter(
        (item: any) => item.indexOf(todayWeek) >= 0,
      );
      const shutdownTimeDt = dayjs()
        .hour(week[0].split(':')[1])
        .minute(week[0].split(':')[2])
        .set('second', 0)
        .set('millisecond', 0);

      if (
        shutdownTimeDt.isAfter(dayjs().subtract(300, 'seconds')) &&
        shutdownTimeDt.isBefore(dayjs())
      ) {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ type: 'REQ_SHUTDOWN' }),
          );
        }
      }
    }

    if (
      deviceInfo.holidays &&
      deviceInfo.holidays.indexOf(dayjs().format('YYYY-MM-DD')) >= 0
    ) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: 'REQ_SHUTDOWN' }),
        );
      }
    }

    if (deviceInfo.onceCommandDt) {
      const onceCommandOldDt = dayjs(onceCommandDt.current);
      const onceCommandRunDt = dayjs(deviceInfo.onceCommandDt)
        .set('second', 0)
        .set('millisecond', 0);
      if (
        onceCommandOldDt.isBefore(
          dayjs().subtract(deviceInfo.agentPeriod, 'seconds'),
        ) &&
        onceCommandRunDt.isBefore(dayjs())
      ) {
        if (deviceInfo.onceCommandNm === 'shutdown') {
        } else if (deviceInfo.onceCommandNm === 'reboot') {
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ type: 'REQ_RESTART' }),
            );
          }
        } else if (deviceInfo.onceCommandNm === 'start') {
        } else if (deviceInfo.onceCommandNm === 'stop') {
        } else if (deviceInfo.onceCommandNm === 'update') {
        }

        onceCommandDt.current = dayjs().format('YYYY-MM-DD HH:mm:ss');
      }
    }
  }, [dispatch, deviceInfo, licenseInfo.deviceToken]);

  useEffect(() => {
    if (agentStatus.actionResult === 'LICENSE_CHECK_OK') {
      dispatch(agentAction.actionResultClear());
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: 'REQ_DEVICE_INFO' }),
        );
      } else {
        dispatch(
          agentAction.deviceManage({ deviceToken: licenseInfo.deviceToken }),
        );
      }
    } else if (agentStatus.actionResult === 'LICENSE_CHECK_ERR') {
      dispatch(agentAction.actionResultClear());

      setTimeout(() => {
        const deviceId = localStorage.getItem('deviceId') || '';
        const licenseKey = localStorage.getItem('licenseKey') || '';
        const machineId = localStorage.getItem('machineId') || '';
        dispatch(agentAction.licenseCheck({ deviceId, licenseKey, machineId }));
      }, 60000);
    } else if (agentStatus.actionResult === 'DEVICE_MANAGE_OK') {
      dispatch(agentAction.actionResultClear());
    }
  }, [dispatch, agentStatus, licenseInfo]);

  useEffect(() => {
    const timerId = setInterval(() => {
      Monitoring();
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [Monitoring]);

  return (
    <AppDiv ref={backgroundEl}>
      <SettingBackground />
      {deviceInfo.deviceId && (
        <ShopInfoBackground deviceId={deviceInfo.deviceId} />
      )}
      {shopInfo.shopId && deviceInfo.deviceId && time && (
        <>
          <WebSocketBackground
            spaceId={shopInfo.spaceId}
            placeId={shopInfo.placeId}
            brandId={shopInfo.brandId}
            shopId={shopInfo.shopId}
            deviceId={deviceInfo.deviceId}
            useNewOrderAlarmYn={useNewOrderAlarmYn}
            usePickUpAlarmYn={usePickUpAlarmYn}
            time={time}
          />
          <OpenInfoBackground
            shopId={shopInfo.shopId}
            deviceId={deviceInfo.deviceId}
          />
        </>
      )}
      {shopInfo.shopId && useNoCheckOrderAlarmYn === 'Y' && (
        <AlertNewOrderBackground currentTime={dayjs(time).format('mm')} />
      )}
      {props.children}
      <Debug
        style={{
          display: process.env.NODE_ENV !== 'production' ? 'block' : 'none',
        }}
      >
        <Time>{time}</Time>
        <License>
          {agentStatus.isLicense ? deviceInfo.deviceNm : 'No License'}
        </License>
        <Version>{localStorage.getItem('deviceId') || ''}</Version>
        <Token>
          {deviceInfo.deviceToken !== '' ? 'token exist' : 'token none'}
        </Token>
      </Debug>
    </AppDiv>
  );
};

const AppDiv = styled.div`
  width: 100vw;
  height: 100vh;
`;

const Debug = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
`;

const Time = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #000000;
`;

const License = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #000000;
`;

const Version = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #000000;
`;

const Token = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #000000;
`;

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
});

export default connect(mapStateToProps, { push })(React.memo(Background));

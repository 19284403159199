import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { history } from '../modules/store';

import { allFlattenRoutes as routes } from './routeSwitch';

import Background from '../pages/background/Background';
import HomeLayout from '../pages/layout/Home';
import DefaultLayout from '../pages/layout/Default';
import ErrorBoundary from '../pages/error/ErrorBoundary';

const Layout = props => {
  const pathname = props.pathname;

  if (pathname === '/' || pathname.indexOf('/home') === 0) {
    return <HomeLayout {...props} />;
  } else {
    return <DefaultLayout {...props} />;
  }
};

const Routes = props => {
  const status = { isLogin: true };

  return (
    <ConnectedRouter history={history}>
      <Background>
        <ErrorBoundary>
          <Layout {...props}>
            <Switch>
              {routes.map((route, index) => {
                return (
                  !route.children && (
                    <route.route
                      key={index}
                      path={route.path}
                      isLogin={status.isLogin}
                      roles={route.roles}
                      exact={route.exact}
                      component={route.component}
                    ></route.route>
                  )
                );
              })}
              <Route render={() => <Redirect to="/" />}></Route>
            </Switch>
          </Layout>
        </ErrorBoundary>
      </Background>
    </ConnectedRouter>
  );
};

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
});

export default connect(mapStateToProps, { push })(React.memo(Routes));

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { settingAction } from '../../modules/settingSlice';

import * as bxlRnPrint from '../../utils/bxlRnPrint';
import { getStorageLabelUse } from '../../utils/labelPrintUtil';
import useLabelPrint from '../../utils/useLabelPrint';

const Setting = () => {
  const dispatch = useDispatch();
  const { labelPrintOpen } = useLabelPrint();

  useEffect(() => {
    const useNewOrderAlarmYn = localStorage.getItem('useNewOrderAlarmYn') || 'Y';
    const usePickUpAlarmYn = localStorage.getItem('usePickUpAlarmYn') || 'Y';
    const useNoCheckOrderAlarmYn = localStorage.getItem('useNoCheckOrderAlarmYn') || 'Y';
    const useReservationOrderAlarmYn = localStorage.getItem('useReservationOrderAlarmYn') || 'Y';
    const useNoCheckReservationOrderAlarmYn = localStorage.getItem('useNoCheckReservationOrderAlarmYn') || 'Y';

    dispatch(settingAction.toogleAlarm({ key: 'useNewOrderAlarmYn' , value: useNewOrderAlarmYn }));
    dispatch(settingAction.toogleAlarm({ key: 'usePickUpAlarmYn' , value: usePickUpAlarmYn }));
    dispatch(settingAction.toogleAlarm({ key: 'useNoCheckOrderAlarmYn' , value: useNoCheckOrderAlarmYn }));
    dispatch(settingAction.toogleAlarm({ key: 'useReservationOrderAlarmYn' , value: useReservationOrderAlarmYn }));
    dispatch(settingAction.toogleAlarm({ key: 'useNoCheckReservationOrderAlarmYn' , value: useNoCheckReservationOrderAlarmYn }));

    localStorage.setItem('useNewOrderAlarmYn', useNewOrderAlarmYn);
    localStorage.setItem('usePickUpAlarmYn', usePickUpAlarmYn);
    localStorage.setItem('useNoCheckOrderAlarmYn', useNoCheckOrderAlarmYn);
    localStorage.setItem('useReservationOrderAlarmYn', useReservationOrderAlarmYn);
    localStorage.setItem('useNoCheckReservationOrderAlarmYn', useNoCheckReservationOrderAlarmYn);
  }, [dispatch]);

  useEffect(() => {
    const _printerUseYn = localStorage.getItem('printerUseYn') || 'N';
    const _isLabelPrintUseYn = getStorageLabelUse();

    if (_printerUseYn === 'Y') {
      bxlRnPrint.open();
    }

    if (_isLabelPrintUseYn) {
      labelPrintOpen();
    }

  }, []);

  return <></>
};

export default React.memo(Setting);
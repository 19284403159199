import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { shopAction } from './shopSlice';

import * as shopApi from '../lib/shopApi';

function* shopInfo({ payload }) {
  try {
    const shopInfo = yield call(shopApi.info, payload);
    yield put(shopAction.shopInfoSuccess({ shopInfo }));
  } catch (err) {
    yield put(shopAction.shopInfoFailure('shopDetail Error'));
  }
}

// function* shopDetail({ payload }) {
//   console.log('payload',payload);
//     try {
//       const shopInfo = yield call(shopApi.detail, payload);
//       yield put(shopAction.shopDetailSuccess({ shopInfo }));
//     } catch (err) {
//       yield put(shopAction.shopDetailFailure('shopDetail Error'));
//     }
// }

function* shopOpenUpdate({ payload }) {
  try {
    const shopInfo = yield call(shopApi.openUpdate, payload);
    yield put(shopAction.shopOpenUpdateSuccess({ shopInfo }));
  } catch (err) {
    yield put(shopAction.shopOpenUpdateFailure('shopOpenUpdate Error'));
  }
}

function* shopAutoOpenUpdate({ payload }) {
  console.log('payload', payload);
  try {
    const shopInfo = yield call(shopApi.autoOpenUpdate, payload);
    yield put(shopAction.shopAutoOpenUpdateSuccess({ shopInfo }));
  } catch (err) {
    yield put(shopAction.shopAutoOpenUpdateFailure('shopAutoOpenUpdate Error'));
  }
}

function* shopAutoStateUpdate({ payload }) {
  try {
    const shopInfo = yield call(shopApi.autoStateUpdate, payload);
    yield put(shopAction.shopAutoStateUpdateSuccess({ shopInfo }));
  } catch (err) {
    yield put(shopAction.shopAutoStateUpdateFailure('shopAutoStateUpdate Error'));
  }
}

function* shopTotalUpdate({ payload }) {
  try {
    console.log('shopTotalUpdate', payload);
    const shopInfo = yield call(shopApi.totalUpdate, payload);
    yield put(shopAction.shopTotalUpdateSuccess({ shopInfo }));
  } catch (err) {
    yield put(shopAction.shopTotalUpdateFailure('shopTotalUpdate Error'));
  }
}

function* shopSaleClose({ payload }) {
  try {
    console.log('shopSaleClose', payload);
    yield call(shopApi.saleClose, payload);
    yield put(shopAction.shopSaleCloseSuccess());
  } catch (err) {
    yield put(shopAction.shopSaleCloseFailure('shopSaleClose Error'));
  }
}

export function* watchShopInfo() {
  yield takeLatest(shopAction.shopInfo, shopInfo);
}
export function* watchShopOpenUpdate() {
  yield takeLatest(shopAction.shopOpenUpdate, shopOpenUpdate);
}
export function* watchShopAutoOpenUpdate() {
  yield takeLatest(shopAction.shopAutoOpenUpdate, shopAutoOpenUpdate);
}
export function* watchShopAutoStateUpdate() {
  yield takeLatest(shopAction.shopAutoStateUpdate, shopAutoStateUpdate);
}
export function* watchShopTotalUpdate() {
  yield takeLatest(shopAction.shopTotalUpdate, shopTotalUpdate);
}
export function* watchShopSaleClose() {
  yield takeLatest(shopAction.shopSaleClose, shopSaleClose);
}

function* rootSaga() {
  yield all([
    fork(watchShopInfo),
    fork(watchShopOpenUpdate),
    fork(watchShopAutoOpenUpdate),
    fork(watchShopAutoStateUpdate),
    fork(watchShopTotalUpdate),
    fork(watchShopSaleClose),
  ]);
}

export default rootSaga;

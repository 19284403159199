import {
    createSlice,
    createDraftSafeSelector,
    PayloadAction,
  } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

interface SalesSelectPayload{
    shopId : string;
    startDt: string;
    endDt: string;
}

interface SalesInsertPayload{
    shopId: string;
    logDt: string;
    totalAmt: string;
    totalEnr: string;
    totalReturnAmt: string;
    totalCnt: string;
    managerId: string;
}

interface SalesDailyInfo{
    logId : string;            
    shopId? : string;          
    logDt? : string;           
    totalAmt? : string;        
    totalEnr? : string;     
    totalReturnAmt? : string;  
    totalCnt? : string;        
    managerId? : string;       
}


interface FilterInfo{
    startDt: string;
    endDt: string;
}

interface SalesDailyState{
    salesDailyInfo : SalesDailyInfo;
    salesDailyList : Array<SalesDailyInfo>;
    filterInfo : FilterInfo;
    actionResult : string;
    isLoading: boolean;
    error: string | null;
}

const salesDailyLogInitialState : SalesDailyState = {
    salesDailyInfo : {logId : '', shopId : '', logDt : '', totalAmt : '', totalEnr : '', totalReturnAmt : '' , totalCnt : '', managerId : '' },
    salesDailyList : [],
    filterInfo : {startDt:'',endDt : ''},
    actionResult : '',
    isLoading: false,
    error: null,
    newFlag : false,
    beforeDaily : {}
}

const reducers = {
  initSalesDailyLog: (state: SalesDailyState) => {
    state.salesDailyList = [];
    state.filterInfo = {startDt:'', endDt : ''};
  },

    salesDailyLogList :(state : SalesDailyState, {payload} : PayloadAction<SalesSelectPayload>) => {
        state.filterInfo.startDt = payload.startDt;
        state.filterInfo.endDt = payload.endDt;
        state.actionResult = 'LIST_REQ';
        state.isLoading = true;
        state.error = null;
    },

    salesDailyLogListSuccess: (state: SalesDailyState, { payload }: PayloadAction<SalesDailyState>) => {
        state.salesDailyList = payload.salesDailyList;
        state.actionResult = 'LIST_OK';
        state.isLoading = false;
        state.error = null;
      },
      salesDailyLogListFailure: (state: SalesDailyState, action: PayloadAction<string>) => {
        state.actionResult = 'LIST_ERR';
        state.isLoading = false;
        state.error = action.payload;
      },
      salesDailyLogAdd  :(state : SalesDailyState, {payload} : PayloadAction<SalesInsertPayload>) => {
        state.actionResult = 'ADD_REQ';
        state.isLoading = true;
        state.error = null;
      }, 
      salesDailyLogAddSuccess: (state: SalesDailyState, { payload }: PayloadAction<SalesDailyState>) => {
        state.salesDailyInfo = payload.result.salesDailyInfo;
        state.actionResult = 'ADD_OK';
        state.newFlag = payload.result.newFlag;
        state.beforeDaily = payload.result.beforeDaily;
        state.isLoading = false;
        state.error = null;
      },
      salesDailyLogAddFailure: (state: SalesDailyState, action: PayloadAction<string>) => {
        state.actionResult = 'ADD_ERR';
        state.isLoading = false;
        state.error = action.payload;
      },
      actionResultClear: (state: AuthState) => {
        state.actionResult = '';
      },


}

const slice = createSlice({
    name: 'salesDailyLog',
    initialState : salesDailyLogInitialState,
    reducers: reducers,
  });

  const selectSalesDailyLogList =  createDraftSafeSelector(
    (state : SalesDailyState) => state.salesDailyList,
    salesDailyList => salesDailyList,
);

const selectSalesDailyLogFilter = createDraftSafeSelector(
    (state : SalesDailyState) => state.filterInfo,
    filterInfo => filterInfo,
);   


const selectStatus = createDraftSafeSelector(
    (state: OpenLogState) => state.actionResult,
    (state: OpenLogState) => state.isLoading,
    (state: OpenLogState) => state.error,
    (state: OpenLogState) => state.newFlag,
    (state: OpenLogState) => state.beforeDaily,
    (actionResult, isLoading, error,newFlag,beforeDaily) => ({ actionResult, isLoading, error,newFlag,beforeDaily }),
  );

  
  export const salesDailyLogSelector = {
      salesDailyLogList : state =>   selectSalesDailyLogList(state[SALESDAILYLOG]),
      salesDailyLogFilter : state => selectSalesDailyLogFilter(state[SALESDAILYLOG]),
      status : state => selectStatus(state[SALESDAILYLOG]),

    }

export const SALESDAILYLOG = slice.name;
export const salesDailyLogReducer = slice.reducer;
export const salesDailyLogAction = slice.actions;

import * as apiLib from './api';

export const categoryList = async ({ brandId }) => {
  try {
    const data = {
      brandId,
    };

    const result = await apiLib.fetchStoreApi('/pos/category/list', data);

    if (result.resultFlag) {
      return result.categoryList;
    } else {
      throw Object.assign(new Error('Pos::Category List Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const categoryUpdate = async ({ categoryId, updateInfo }) => {
  try {
    const data = {
      categoryId,
      updateInfo,
    };

    const result = await apiLib.fetchStoreApi('/pos/category/update', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Pos:: Category Update Api Error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    // throw err;
  }
};

export const goodsList = async ({ brandId, shopId, categoryId }) => {
  try {
    const data = {
      brandId,
      shopId,
      categoryId
    };

    const result = await apiLib.fetchStoreApi('/pos/goods/list', data);

    if (result.resultFlag) {
      return result.goodsList;
    } else {
      throw Object.assign(new Error('Pos::Goods List Api error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const goodsDetail = async ({ brandId, shopId, goodsId }) => {
  try {
    const data = {
      brandId,
      shopId,
      goodsId,
    };

    const result = await apiLib.fetchStoreApi('/pos/goods/detail', data);

    if (result.resultFlag) {
      return result.goodsInfo;
    } else {
      throw Object.assign(new Error('Pos::Goods Detail Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const goodsEdit = async ({ shopId, updateInfo }) => {
  try {
    const data = {
      shopId,
      updateInfo
    };

    const result = await apiLib.fetchStoreApi('/pos/goods/edit', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Pos::Goods Edit Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const stockUpdate = async ({ shopId, stockId, updateInfo }) => {
  try {
    const data = {
      shopId,
      stockId,
      updateInfo
    };

    const result = await apiLib.fetchStoreApi('/pos/goods/stock-update', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Pos::Stock Update Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const changeDisplayAll = async ({ shopId, stockList, displayYn }) => {
  try {
    const data = {
      shopId,
      stockList,
      displayYn
    };

    const result = await apiLib.fetchStoreApi('/pos/goods/change-display-all', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Pos::Change Display All Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

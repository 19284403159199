import * as apiLib from './api';


export const openLogInfo = async ({ shopId, openStartDt, openEndDt }) => {
  try {
    const data = {
      shopId,
      openStartDt,
      openEndDt,
    };

    const result = await apiLib.fetchStoreApi('/pos/openLog/openLogInfo', data);
    if (result.resultFlag) {
      return result.openLogList;
    } else {
      throw Object.assign(new Error('openLogInfo error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};



export const list = async ({ shopId, openStartDt, openEndDt }) => {

  try {
    const data = {
      shopId,
      openStartDt,
      openEndDt,
    };

    const result = await apiLib.fetchStoreApi('/pos/openLog/list', data);
    if (result.resultFlag) {
      return result.openLogList;
    } else {
      throw Object.assign(new Error('open error'), { status: 401 });
    }
  } catch (err) {
      console.log(err);
    throw err;
  }
};

export const logAdd = async ({ deviceId,deviceNm,shopId, logDt, shopOpenState,endAutoState,regDt }) => {

  try {
    const data = {
      deviceId,
      deviceNm,
      shopId,
      logDt,
      shopOpenState,
      endAutoState,
      // managerId,
      regDt 
    };


    const result = await apiLib.fetchStoreApi('/pos/openLog/log-add', data);
    if (result.resultFlag) {
      return result.openLog;
    } else {
      throw Object.assign(new Error('log-add error'), { status: 401 });
    }
  } catch (err) {
      console.log(err);
    throw err;
  }
};

import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import styled, { css } from 'styled-components';

import ClickOutside from '../../components/ClickOutside';

import closeIcon from '../../assets/images/common/navigation/ic_close.png';
import homeIcon from '../../assets/images/common/navigation/ic_m_home.png';
import homeIconActive from '../../assets/images/common/navigation/ic_m_home_s.png';
import storeIcon from '../../assets/images/common/navigation/ic_m_openclose.png';
import storeIconActive from '../../assets/images/common/navigation/ic_m_openclose_s.png';
import orderIcon from '../../assets/images/common/navigation/ic_m_order.png';
import orderIconActive from '../../assets/images/common/navigation/ic_m_order_s.png';
import goodsIcon from '../../assets/images/common/navigation/ic_m_product.png';
import goodsIconActive from '../../assets/images/common/navigation/ic_m_product_s.png';
import saleIcon from '../../assets/images/common/navigation/ic_m_sales.png';
import saleIconActive from '../../assets/images/common/navigation/ic_m_sales_s.png';
import deviceIcon from '../../assets/images/common/navigation/ic_m_pos.png';
import deviceIconActive from '../../assets/images/common/navigation/ic_m_pos_s.png';
import settingIcon from '../../assets/images/common/navigation/ic_m_setting.png';
import settingIconActive from '../../assets/images/common/navigation/ic_m_setting_s.png';

const NavBar = props => {

  const onMenuClick = useCallback(
    url => {
      props.setIsMenuOpen(false);
      props.push(url);
    },
    [props],
  );

  const checkIsActive = useCallback(
    pathname => {
      return props.pathname.indexOf(pathname) >= 0;
    },
    [props.pathname],
  );

  const closeNavbar = useCallback(() => {
    if (props.isMenuOpen) {
      props.setIsMenuOpen(false);
    }
  }, [props]);

  return (
    <Container isMenuOpen={props.isMenuOpen}>
      <ClickOutside onClickOutside={closeNavbar}>
        <span className="menu-close" onClick={() => props.setIsMenuOpen(false)}>
          <img src={closeIcon} alt="메뉴 닫기" />
        </span>
        <ul className="menu-list">
          <li className="menu" onClick={e => onMenuClick('/home')}>
            <img src={homeIcon} alt="홈으로" />
            <span>HOME</span>
          </li>
          <li
            className={`menu ${checkIsActive('/open') ? 'active' : ''}`}
            onClick={e => onMenuClick('/open')}
          >
            <img
              src={checkIsActive('/open') ? storeIconActive : storeIcon}
              alt="영업관리"
            />
            <span>영업관리</span>
          </li>
          <li
            className={`menu ${checkIsActive('/order') ? 'active' : ''}`}
            onClick={e => onMenuClick('/order/all')}
          >
            <img
              src={checkIsActive('/order') ? orderIconActive : orderIcon}
              alt="주문관리"
            />
            <span>주문관리</span>
          </li>
          <li
            className={`menu ${checkIsActive('/goods') ? 'active' : ''}`}
            onClick={e => onMenuClick('/goods/list')}
          >
            <img
              src={checkIsActive('/goods') ? goodsIconActive : goodsIcon}
              alt="상품관리"
            />
            <span>상품관리</span>
          </li>
          <li
            className={`menu ${checkIsActive('/report') ? 'active' : ''}`}
            onClick={e => onMenuClick('/report/sales')}
          >
            <img
              src={checkIsActive('/report') ? saleIconActive : saleIcon}
              alt="매출관리"
            />
            <span>매출관리</span>
          </li>
          <li
            className={`menu ${checkIsActive('/setting') ? 'active' : ''}`}
            onClick={e => onMenuClick('/setting/alarm')}
          >
            <img
              src={checkIsActive('/setting') ? settingIconActive : settingIcon}
              alt="설정"
            />
            <span>설정</span>
          </li>
        </ul>
      </ClickOutside>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 250px;
  display: flex;
  left: ${({ isMenuOpen }) => isMenuOpen ? 0 : '-250px'};
  transition: all .3s ease-out;
  flex-direction: column;
  position: fixed;
  background: #2d373e;
  z-index: 1001;
  padding: 30px 20px;
  .menu-close {
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 10px;
    cursor: pointer;
  }
  .menu-list {
    max-width: 200px;
    height: 44px;
    .menu {
      margin-top: 20px;
      padding: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #ccc;
      &:first-child {
        margin-top: 0;
      }
      img {
        width: 17px;
        height: 17px;
        object-fit: contain;
      }
      span {
        font-weight: 500;
        font-size: 18px;
        margin-left: 22px;
      }
      &.active {
        color: #779eff;
      }
    }
  }
`;

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
});

export default connect(mapStateToProps, { push })(React.memo(NavBar));

import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface AgentPayload {
  pairingId: string;
  deviceId: string;
  machineId: string;
  deviceId: string;
  deviceToken: string;
}

interface PairingInfo {
  deviceId?: string;
  licenseKey?: string;
}

interface LicenseInfo {
  deviceToken?: string;
}

interface DeviceInfo {
  deviceId?: string;
  deviceNm?: string;
}

interface AgentState {
  pairingInfo: PairingInfo;
  licenseInfo: LicenseInfo;
  deviceInfo: DeviceInfo;
  isLauncher: boolean;
  isNetwork: boolean;
  isLicense: boolean;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const agentInitialState: AgentState = {
  pairingInfo: {},
  licenseInfo: {},
  deviceInfo: {},
  isLauncher: false,
  isNetwork: false,
  isLicense: false,
  actionResult: 'INIT',
  isLoading: false,
  error: null,
};

const reducers = {
  pairing: (state: PairingState, { payload }: PayloadAction<AgentPayload>) => {
    state.actionResult = 'PAIRING_REQ';
    state.isLoading = true;
    state.error = null;
  },
  pairingSuccess: (
    state: AgentState,
    { payload }: PayloadAction<AgentState>
  ) => {
    state.actionResult = 'PAIRING_OK';
    state.pairingInfo = payload.pairingInfo;
    state.isLoading = false;
    state.error = null;
  },
  pairingFailure: (state: AgentState, action: PayloadAction<string>) => {
    state.actionResult = 'PAIRING_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  licenseCheck: (
    state: AgentState,
    { payload }: PayloadAction<AgentPayload>
  ) => {
    state.actionResult = 'LICENSE_CHECK_REQ';
    state.isLoading = true;
    state.error = null;
  },
  licenseCheckSuccess: (
    state: AgentState,
    { payload }: PayloadAction<AgentState>
  ) => {
    state.actionResult = 'LICENSE_CHECK_OK';
    state.licenseInfo = payload.licenseInfo;
    state.isNetwork = true;
    state.isLicense = true;
    state.isLoading = false;
    state.error = null;
  },
  licenseCheckFailure: (state: AgentState, action: PayloadAction<string>) => {
    state.actionResult = 'LICENSE_CHECK_ERR';
    state.isNetwork = true;
    state.isLicense = false;
    state.isLoading = false;
    state.error = action.payload;
  },
  deviceManage: (
    state: AgentState,
    { payload }: PayloadAction<AgentPayload>
  ) => {
    state.actionResult = 'DEVICE_MANAGE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  deviceManageSuccess: (
    state: AgentState,
    { payload }: PayloadAction<AgentState>
  ) => {
    state.actionResult = 'DEVICE_MANAGE_OK';
    state.deviceInfo = payload.deviceInfo;
    state.isNetwork = true;
    state.isLicense = true;
    state.isLoading = false;
    state.error = null;
  },
  deviceManageFailure: (state: AgentState, action: PayloadAction<string>) => {
    state.actionResult = 'DEVICE_MANAGE_ERR';
    state.isNetwork = true;
    state.isLicense = false;
    state.isLoading = false;
    state.error = action.payload;
  },
  scrshotUpload: (
    state: AgentState,
    { payload }: PayloadAction<AgentPayload>
  ) => {
    state.actionResult = 'SCRSHOT_UPLOAD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  scrshotUploadSuccess: (
    state: AgentState,
    { payload }: PayloadAction<AgentState>
  ) => {
    state.actionResult = 'SCRSHOT_UPLOAD_OK';
    state.isLoading = false;
    state.error = null;
  },
  scrshotUploadFailure: (state: AgentState, action: PayloadAction<string>) => {
    state.actionResult = 'SCRSHOT_UPLOAD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: AgentState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'agent',
  initialState: agentInitialState,
  reducers: reducers,
});

const selectPairingInfo = createDraftSafeSelector(
  (state: AgentState) => state.pairingInfo,
  (pairingInfo) => pairingInfo
);

const selectLicenseInfo = createDraftSafeSelector(
  (state: AgentState) => state.licenseInfo,
  (licenseInfo) => licenseInfo
);

const selectDeviceInfo = createDraftSafeSelector(
  (state: AgentState) => state.deviceInfo,
  (deviceInfo) => deviceInfo
);

const selectStatus = createDraftSafeSelector(
  (state: AgentState) => state.isLauncher,
  (state: AgentState) => state.isNetwork,
  (state: AgentState) => state.isLicense,
  (state: AgentState) => state.actionResult,
  (state: AgentState) => state.isLoading,
  (state: AgentState) => state.error,
  (isLauncher, isNetwork, isLicense, actionResult, isLoading, error) => ({
    isLauncher,
    isNetwork,
    isLicense,
    actionResult,
    isLoading,
    error,
  })
);

export const agentSelector = {
  pairingInfo: (state) => selectPairingInfo(state[AGENT]),
  licenseInfo: (state) => selectLicenseInfo(state[AGENT]),
  deviceInfo: (state) => selectDeviceInfo(state[AGENT]),
  status: (state) => selectStatus(state[AGENT]),
};

export const AGENT = slice.name;
export const agentReducer = slice.reducer;
export const agentAction = slice.actions;

import dayjs from 'dayjs';

const LABEL_PRINT_OPTION_STORAGE = 'labelPrintOptions';
const LABEL_PRINT_USE = 'isLabelPrintUse';
const LABEL_AUTO_CUT = 'isLabelAutoCut';
const LABEL_COUNT = 'labelPrintCount';
const NAME_MAX_LENGTH = 16;
const ENG_NAME_MAX_LENGTH = 18;

const ITEM_MAX_LENGHT = 16;

const TAKE_TYPE = {
  IN: '매장에서 픽업',
  ROBOT: '로봇 배달',
  OUT: '포장',
  REZ: '예약',
  DELI_REZ: '예약 배달',
};

const BixolonLabelPrinter = {
  FONT_SIZE_6: 48, // 9 x 15 dots
  FONT_SIZE_8: 49, // 12 x 20 dots
  FONT_SIZE_10: 50, // 16 x 25 dots
  FONT_SIZE_12: 51, // 19 x 30 dots
  FONT_SIZE_15: 52, // 24 x 38 dots
  FONT_SIZE_20: 53, // 32 x 40 dots
  FONT_SIZE_30: 54, // 48 x 76 dots
  FONT_SIZE_14: 55, // 22 x 34 dots
  FONT_SIZE_18: 56, // 28 x 44 dots
  FONT_SIZE_24: 57, // 37 x 58 dots
  FONT_SIZE_KOREAN1: 97, // ASCII 9 x 15
  FONT_SIZE_KOREAN2: 98, // ASCII 12 x 24 (24 x 24 dots)
  FONT_SIZE_KOREAN3: 99, // ASCII 12 x 20 (20 x 20 dots)
  FONT_SIZE_KOREAN4: 100, // ASCII 16 x 30 (26 x 26 dots)
  FONT_SIZE_KOREAN5: 101, // ASCII 16 x 30 (20 x 20 dots)
  FONT_SIZE_KOREAN6: 102, // ASCII 22 x 34 (38 x 38 dots)
  FONT_SIZE_GB2312: 109, // ASCII 12 x 24 (24 x 24 dots)
  FONT_SIZE_BIG5: 110, // ASCII 12 x 24 (24 x 24 dots)
  FONT_SIZE_SHIFT_JIS: 106, // ASCII 12 x 24 (24 x 24 dots)
  ROTATION_NONE: 0, // 회전하지 않음
  ROTATION_90_DEGREES: 1, // 90도 회전
  ROTATION_180_DEGREES: 2, // 180도 회전
  ROTATION_270_DEGREES: 3, // 270도 회전
  TEXT_ALIGNMENT_NONE: 48, // 정렬 없음
  TEXT_ALIGNMENT_LEFT: 70, // 왼쪽 정렬
  TEXT_ALIGNMENT_RIGHT: 76, // 오른쪽 정렬
  TEXT_ALIGNMENT_RIGHT_TO_LEFT: 82, // 문자열을 오른쪽에서 왼쪽 방향으로 출력
  BLOCK_OPTION_LINE_OVERWRITING: 79, // Line Overwriting
  BLOCK_OPTION_LINE_EXCLUSIVE_OR: 69, // Line Exclusive OR
  BLOCK_OPTION_LINE_DELETE: 68, // Line Delete
  BLOCK_OPTION_SLOPE: 83, // Slope(a oblique line)
  BLOCK_OPTION_BOX: 66, // Box
};

export const getStorageLabelOption = () => {
  const labelStoredStates = localStorage.getItem(LABEL_PRINT_OPTION_STORAGE);
  const labelOptions = JSON.parse(labelStoredStates || '{}');

  return {
    orderNo: labelOptions?.orderNo === false ? false : true,
    customerName: labelOptions?.customerName === false ? false : true,
    mandatoryOptions: labelOptions?.mandatoryOptions === false ? false : true,
    options: labelOptions?.options === false ? false : true,
    orderTime: labelOptions?.orderTime === false ? false : true,
    pickupType: labelOptions?.pickupType === false ? false : true,
    productName: labelOptions?.productName === false ? false : true,
    request: labelOptions?.request === false ? false : true,
    storeName: labelOptions?.storeName === false ? false : true,
  };
};

export const setStorageLabelOption = data => {
  localStorage.setItem(LABEL_PRINT_OPTION_STORAGE, JSON.stringify(data));
};

export const getStorageLabelUse = () => {
  return localStorage.getItem(LABEL_PRINT_USE) === 'true' ? true : false;
};

export const setStorageLabelUse = data => {
  localStorage.setItem(LABEL_PRINT_USE, data);
};

export const getStorageLabelCut = () => {
  return localStorage.getItem(LABEL_AUTO_CUT) === 'true' ? true : false;
};

export const setStorageLabelCut = data => {
  localStorage.setItem(LABEL_AUTO_CUT, data);
};

export const getLabelCount = date => {
  const labelCounts = JSON.parse(localStorage.getItem(LABEL_COUNT) || '{}');
  return labelCounts[date] || 0;
};

export const getLabelCountAll = () => {
  const labelCounts = localStorage.getItem(LABEL_COUNT);

  alert(labelCounts);
};

export const addLabelCount = date => {
  const labelCounts = JSON.parse(localStorage.getItem(LABEL_COUNT) || '{}');
  labelCounts[date] = (labelCounts[date] || 0) + 1;
  localStorage.setItem(LABEL_COUNT, JSON.stringify(labelCounts));
};

const LabelTextDefaultOption = {
  type: 'text',
  widthSize: 1, //가로 확대 비율
  heightSize: 1, // 세로 확대 비율
  rightSpace: 0,
  rotation: BixolonLabelPrinter.ROTATION_90_DEGREES,
  reverse: false,
  bold: false,
  alignment: BixolonLabelPrinter.TEXT_ALIGNMENT_LEFT,
};

const LabelBlockDefaultOption = {
  type: 'block',
  option: 1,
  thickness: 1,
};

const DrawLine = width => {
  return {
    ...LabelBlockDefaultOption,
    startWidth: width,
    startHeight: 0,
    endWidth: width + 1,
    endHeight: 400,
    option: BixolonLabelPrinter.BLOCK_OPTION_LINE_OVERWRITING,
  };
};

const splitText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return [text];
  }

  const result = [];
  let start = 0;

  while (start < text.length && result.length < 4) {
    const end = Math.min(start + maxLength, text.length);
    result.push(text.substring(start, end));
    start = end;
  }

  // 마지막 부분 처리
  if (start < text.length) {
    // 남은 텍스트 처리
    let lastPart = text.substring(start);
    if (lastPart.length > maxLength) {
      // 마지막 부분이 maxLength보다 길면, 2자리를 자르고 '...' 추가
      lastPart = lastPart.substring(0, maxLength - 2) + '...';
    }
    result.push(lastPart);
  }

  return result;
};

const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...';
  }
  return str;
};

export const orderLabelText = (order, isTest, date) => {
  const labelText = [];
  const labelOptions = getStorageLabelOption();
  const startHeight = 20;

  // 주문번호
  if (labelOptions.orderNo) {
    labelText.push({
      ...LabelTextDefaultOption,
      text: order.billNo,
      width: 690,
      height: startHeight,
      bold: true,
      fontSize: BixolonLabelPrinter.FONT_SIZE_20, // font size 변경
      //fontSize: BixolonLabelPrinter.FONT_SIZE_KOREAN6,
    });
  }

  //상품 순서 , 총주문상품
  labelText.push({
    ...LabelTextDefaultOption,
    text: `${order.itemIndex}/${order.itemTotal}`,
    width: 690,
    height: 320,
    fontSize: BixolonLabelPrinter.FONT_SIZE_KOREAN2,
  });

  // 주문자 명 / 영문명
  if (labelOptions.customerName) {
    // labelText.push({
    //   ...LabelTextDefaultOption,
    //   text: truncateString(order.customerNm, NAME_MAX_LENGTH),
    //   width: 670,
    //   height: startHeight,
    //   fontSize: BixolonLabelPrinter.FONT_SIZE_KOREAN4,
    //   bold: true,
    // });

    labelText.push({
      ...LabelTextDefaultOption,
      text: truncateString(order.customerNm ? order.customerNm : order.customerEngNm, NAME_MAX_LENGTH),
      width: 630,
      height: startHeight,
      fontSize: BixolonLabelPrinter.FONT_SIZE_KOREAN4,
      bold: true,
    });
  }

  if (labelOptions.customerName || labelOptions.orderNo) {
    //Line Draw
    labelText.push({
      ...DrawLine(600),
    });
  }

  //상품명
  if (labelOptions.productName) {
    labelText.push({
      ...LabelTextDefaultOption,
      text: truncateString(order.itemNm, ITEM_MAX_LENGHT),
      width: 570,
      height: startHeight,
      fontSize: BixolonLabelPrinter.FONT_SIZE_KOREAN4,
      bold: true,
    });
  }

  //필수 옵션
  if (labelOptions.mandatoryOptions) {
    labelText.push({
      ...LabelTextDefaultOption,
      text: order.goodsOpt ? `* ${order.goodsOpt}` : '',
      width: 530, //가로 넓이
      height: startHeight + 10, //세로 넓이
      fontSize: BixolonLabelPrinter.FONT_SIZE_KOREAN4,
      bold: true,
    });
  }

  if (labelOptions.productName || labelOptions.mandatoryOptions) {
    labelText.push({
      ...DrawLine(490),
    });
  }

  let blockWidth = 480;

  //옵션
  if (labelOptions.options) {
    labelText.push({
      ...LabelTextDefaultOption,
      text: `Option(${order.optNm.length})`,
      width: blockWidth, //가로 넓이
      height: startHeight, //세로 넓이
      fontSize: BixolonLabelPrinter.FONT_SIZE_KOREAN2,
      rotation: BixolonLabelPrinter.ROTATION_90_DEGREES,
      //reverse: true,
      bold: true,
    });

    blockWidth = blockWidth - 40;

    const splitOptNm = splitText(order.optNm.join(', '), 22);

    splitOptNm.forEach((optText, index) => {
      // 쉼표로 시작하는 경우 처리
      if (optText.startsWith(',') && index > 0) {
        // 이전 요소에 쉼표 추가
        labelText[labelText.length - 1].text += ',';
        // 현재 요소에서 쉼표 제거
        optText = optText.substring(1);
      }

      labelText.push({
        ...LabelTextDefaultOption,
        text: optText,
        width: blockWidth,
        height: startHeight,
        fontSize: BixolonLabelPrinter.FONT_SIZE_KOREAN3,
      });
      blockWidth = blockWidth - 30;
    });

    blockWidth = blockWidth - 10;
    //Line Draw
    labelText.push({
      ...DrawLine(blockWidth),
    });
    blockWidth = blockWidth - 10;
  }

  //주문시간
  if (labelOptions.orderTime) {
    labelText.push({
      ...LabelBlockDefaultOption,
      startWidth: blockWidth,
      startHeight: startHeight,
      endWidth: blockWidth - 20,
      endHeight: startHeight + 20,
      option: BixolonLabelPrinter.BLOCK_OPTION_BOX,
    });

    labelText.push({
      ...LabelTextDefaultOption,
      text: order.orderWaitDt,
      width: blockWidth, //가로 넓이
      height: 50, //세로 넓이
      fontSize: BixolonLabelPrinter.FONT_SIZE_KOREAN2,
    });

    blockWidth = blockWidth - 40;
  }

  //픽업유형
  if (labelOptions.pickupType) {
    labelText.push({
      ...LabelBlockDefaultOption,
      startWidth: blockWidth,
      startHeight: startHeight,
      endWidth: blockWidth - 20,
      endHeight: startHeight + 20,
      option: BixolonLabelPrinter.BLOCK_OPTION_BOX,
    });

    labelText.push({
      ...LabelTextDefaultOption,
      text: TAKE_TYPE[order.takeType] ?? order.takeType,
      width: blockWidth, //가로 넓이
      height: 50, //세로 넓이
      fontSize: BixolonLabelPrinter.FONT_SIZE_KOREAN2,
    });

    blockWidth = blockWidth - 40;
  }

  //매장명
  if (labelOptions.storeName) {
    labelText.push({
      ...LabelBlockDefaultOption,
      startWidth: blockWidth,
      startHeight: startHeight,
      endWidth: blockWidth - 20,
      endHeight: startHeight + 20,
      option: BixolonLabelPrinter.BLOCK_OPTION_BOX,
    });

    labelText.push({
      ...LabelTextDefaultOption,
      text: order.shopNm,
      width: blockWidth, //가로 넓이
      height: 50, //세로 넓이
      fontSize: BixolonLabelPrinter.FONT_SIZE_KOREAN2,
    });
  }

  if (isTest) {
    labelText.push({
      ...LabelTextDefaultOption,
      text: '라벨 프린터 TEST 출력',
      width: blockWidth - 40, //가로 넓이
      height: startHeight, //세로 넓이
      fontSize: BixolonLabelPrinter.FONT_SIZE_KOREAN2,
      bold: true,
    });

    labelText.push({
      ...LabelTextDefaultOption,
      text: `O 영업시작 : ${order.currentOpenDt}`,
      width: blockWidth - 70, //가로 넓이
      height: startHeight + 20, //세로 넓이
      fontSize: BixolonLabelPrinter.FONT_SIZE_KOREAN1,
    });

    labelText.push({
      ...LabelTextDefaultOption,
      text: `O 현재시간 : ${dayjs().format('YYYY년 MM월 DD일 HH:mm:ss')}`,
      width: blockWidth - 90, //가로 넓이
      height: startHeight + 20, //세로 넓이
      fontSize: BixolonLabelPrinter.FONT_SIZE_KOREAN1,
    });

    labelText.push({
      ...LabelTextDefaultOption,
      text: `O 라벨 인쇄 수량 : ${getLabelCount(date)}개 `,
      width: blockWidth - 110, //가로 넓이
      height: startHeight + 20, //세로 넓이
      fontSize: BixolonLabelPrinter.FONT_SIZE_KOREAN1,
    });
  }

  return labelText;
};

import React from 'react';
import { Route } from 'react-router-dom';

const Home = React.lazy(() => import('../pages/home/Home'));
const Pairing = React.lazy(() => import('../pages/home/Pairing'));
const Open = React.lazy(() => import('../pages/open/Sales'));
const Order = React.lazy(() => import('../pages/order/Order'));
const Payment = React.lazy(() => import('../pages/order/Payment'));
const GoodsList = React.lazy(() => import('../pages/goods/GoodsList'));
const Report = React.lazy(() => import('../pages/report/Report'));
const Setting = React.lazy(() => import('../pages/setting/Setting'));

const PrivateRoute = ({ component: Component, isLogin, roles, ...rest }) => {
  return <Route {...rest}>{!isLogin ? '페어링' : <Component />}</Route>;
};

const rootRoute = {
  path: '/',
  exact: true,
  component: Pairing,
  route: Route,
};

const homeRoutes = {
  path: '/home',
  name: 'Home',
  children: [
    {
      path: '/home/pairing',
      name: 'Pairing',
      component: Pairing,
      route: PrivateRoute,
      roles: ['Admin', 'User'],
    },
    {
      path: '/home',
      name: 'Home',
      component: Home,
      route: PrivateRoute,
      roles: ['Admin', 'User'],
    },
  ],
};

const openRoutes = {
  path: '/open',
  name: 'Open',
  component: Open,
  route: PrivateRoute,
  roles: ['Admin', 'User'],
};

const orderRoutes = {
  path: '/order',
  name: 'Order',
  children: [
    {
      path: '/order/payment',
      name: 'Payment',
      component: Payment,
      route: PrivateRoute,
      roles: ['Admin', 'User'],
    },
    {
      path: '/order',
      name: 'Order',
      component: Order,
      route: PrivateRoute,
      roles: ['Admin', 'User'],
    },
  ],
};

const goodsRoutes = {
  path: '/goods',
  name: 'Goods',
  children: [
    {
      path: '/goods/list',
      name: 'GoodsList',
      component: GoodsList,
      route: PrivateRoute,
      roles: ['Admin', 'User'],
    },
  ],
};

const reportRoutes = {
  path: '/report',
  name: 'Report',
  children: [
    {
      path: '/report/sales',
      name: 'Report',
      component: Report,
      route: PrivateRoute,
      roles: ['Admin', 'User'],
    },
    {
      path: '/report/analysis',
      name: 'Report',
      component: Report,
      route: PrivateRoute,
      roles: ['Admin', 'User'],
    },
  ],
};

const settingRoutes = {
  path: '/setting',
  name: 'Setting',
  component: Setting,
  route: PrivateRoute,
  roles: ['Admin', 'User'],
};

const flattenRoutes = routes => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach(item => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

const allRoutes = [
  rootRoute,
  homeRoutes,
  openRoutes,
  orderRoutes,
  goodsRoutes,
  reportRoutes,
  settingRoutes,
];

const authProtectedRoutes = [
  homeRoutes,
  openRoutes,
  orderRoutes,
  goodsRoutes,
  reportRoutes,
  settingRoutes,
];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };

import React, { Suspense, useState } from 'react';
import styled from 'styled-components';

import Header from '../common/Header';
import NavBar from '../common/NavBar';

const loading = () => <div className="text-center"></div>;

const Default = props => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Container>
      <Suspense fallback={loading()}>
        <NavBar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      </Suspense>
      <Suspense fallback={loading()}>
        <Header setIsMenuOpen={setIsMenuOpen} />
      </Suspense>
      <Suspense fallback={loading()}>{props?.children || null}</Suspense>
    </Container>
  );
};

const Container = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export default Default;

import * as apiLib from './api';

export const info = async ({ deviceId }) => {
  try {
    const data = {
      deviceId,
    };

    const result = await apiLib.fetchStoreApi('/pos/shop/info', data);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop info error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const detail = async ({ shopId }) => {
  try {
    const data = {
      shopId,
    };

    const result = await apiLib.fetchStoreApi('/pos/shop/detail', data);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop detail error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const openUpdate = async ({ shopId, openState, beforeOpenDt }) => {
  console.log('beforeOpenDt', beforeOpenDt);

  try {
    const data = {
      shopId,
      openState,
      beforeOpenDt,
    };

    const result = await apiLib.fetchStoreApi('/pos/shop/open-update', data);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop openState error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const autoOpenUpdate = async ({ shopId, autoOpenState }) => {
  try {
    const data = {
      shopId,
      autoOpenState,
    };

    const result = await apiLib.fetchStoreApi('/pos/shop/auto-open-update', data);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop autoOpenUpdate error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const autoStateUpdate = async ({ shopId, autoOpenTime, autoEndTime, autoWorkDay }) => {
  try {
    const data = {
      shopId,
      autoOpenTime,
      autoEndTime,
      autoWorkDay,
    };

    const result = await apiLib.fetchStoreApi('/pos/shop/auto-state-update', data);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop autoStateUpdate error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const totalUpdate = async ({
  shopId,
  openState,
  openChange,
  beforeOpenDt,
  autoOpenState,
  autoOpenTime,
  autoEndTime,
  autoWorkDay,
}) => {
  try {
    const data = {
      shopId,
      openState,
      openChange,
      beforeOpenDt,
      autoOpenState,
      autoOpenTime,
      autoEndTime,
      autoWorkDay,
    };
    console.log('data', data);

    const result = await apiLib.fetchStoreApi('/pos/shop/total-state-update', data);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop autoStateUpdate error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const socketPing = async ({ shopId }) => {
  try {
    const data = {
      shopId,
    };

    const result = await apiLib.fetchStoreApi('/pos/shop/socket-ping', data);
    if (result.resultFlag) {
      return result.resultFlag;
    } else {
      throw Object.assign(new Error('socket ping error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saleClose = async ({ shopId }) => {
  try {
    const data = {
      shopId,
    };

    const result = await apiLib.fetchStoreApi('/pos/shop/sale-close', data);
    if (result.resultFlag) {
      return result.resultFlag;
    } else {
      throw Object.assign(new Error('sale close error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

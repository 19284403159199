import * as apiLib from './api';

export const token = async ({ playToken }) => {
  try {
    const data = {
      token: playToken,
    };

    const result = await apiLib.fetchStoreApi('/player/token', data);
    if (result.resultFlag) {
      return result.playInfo;
    } else {
      throw Object.assign(new Error('playlist error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const playlist = async ({ deviceToken, playlistId }) => {
  try {
    const data = {
      deviceToken,
      playlistId,
    };

    const result = await apiLib.fetchStoreApi('/player/playlist', data);
    if (result.resultFlag) {
      return {
        contentWidth: Number(result.playlistInfo.horizonResolution),
        contentHeight: Number(result.playlistInfo.verticalResolution),
        playlistInfo: result.playlistInfo,
        frameList: result.frameList,
        layerList: result.layerList,
        layerContentsList: result.layerContentsList,
        overlayList: result.overlayList,
      };
    } else {
      throw Object.assign(new Error('playlist error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

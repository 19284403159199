import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';

interface SalesSelectPayload{
  shopId : string;
  startDt: string;
  endDt: string;
}

interface SalesInsertPayload{
  shopId: string;
  logDt: string;
  categoryInfoList : Array<any>;
  goodsInfoList : Array<any>;
  managerId : string;
}

interface AnalysisInfo{
  logId : string;
  shopId : string;
  logDt : string;
  categoryInfoList : Array<categoryInfo>;
  goodsInfoList : Array<goodsInfo>;
  managerId : string;
}


interface FilterInfo{
  startDt: string;
  endDt: string;
}

interface PageInfo {
  rowsPerPage: number;
  curPage: number;
}

interface SalesAnalysisState{
  analysisInfo : AnalysisInfo;
  analysisList : Array<AnalysisInfo>;
  filterInfo : FilterInfo;
  pageInfo: PageInfo;
  actionResult : string;
  isLoading: boolean;
  error: string | null;
}

const salesAnalysisLogInitialState : SalesAnalysisState = {
  analysisList : [],
  analysisInfo : {logId :'',shopId : '',logDt : '',categoryInfoList : [],goodsInfoList : [],managerId : ''},
  filterInfo : {startDt: '', endDt: ''},
  pageInfo: {rowsPerPage: 10, curPage: 1},
  actionResult : '',
  isLoading: false,
  error: null,
}

const reducers = {
  initSalesAnalysisLog: (state: SalesAnalysisState) => {
    state.analysisList = [];
    state.filterInfo = { startDt: '', endDt: '' };
    state.pageInfo = {rowsPerPage: 10, curPage: 1};
  },
  salesAnalysisLogList :(state : SalesAnalysisState, {payload} : PayloadAction<SalesSelectPayload>) => {
    // state.filterInfo.startDt = payload.startDt;
    // state.filterInfo.endDt = payload.endDt;
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  salesAnalysisLogListSuccess: (state: SalesAnalysisState, { payload }: PayloadAction<SalesAnalysisState>) => {
    state.analysisList = payload.analysisList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  salesAnalysisLogListFailure: (state: SalesAnalysisState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  salesAnalysisLogAdd  :(state : SalesAnalysisState, {payload} : PayloadAction<SalesInsertPayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = null;
  }, 
  salesAnalysisLogAddSuccess: (state: SalesAnalysisState, { payload }: PayloadAction<SalesAnalysisState>) => {
    state.analysisInfo = payload.analysisInfo;
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  salesAnalysisLogAddFailure: (state: SalesAnalysisState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  salesAnalysisFilter: (state: SalesAnalysisState, { payload }: PayloadAction<string>) => {
    state.filterInfo.startDt = payload.startDt;
    state.filterInfo.endDt = payload.endDt;
  },
  salesAnalysisPaging: (state: ReportState, { payload: {key, value} }: PayloadAction<ReportState>) => {
    state.pageInfo[key] = value;
  },
}


const slice = createSlice({
  name: 'salesAnalysisLog',
  initialState : salesAnalysisLogInitialState,
  reducers: reducers,
});


const selectSalesAnalysisLogList = createDraftSafeSelector(
  (state : any) => state.salesAnalysisLog.analysisList,
  analysisList => analysisList
);

const selectSalesAnalysisLogFilter = createDraftSafeSelector(
  (state : any) => state.salesAnalysisLog.filterInfo,
  filterInfo => filterInfo,
);

const selectFilterAnalysisList = createDraftSafeSelector(
  (state: any) => state.salesAnalysisLog.analysisList,
  (state: any) => state.salesAnalysisLog.pageInfo,

  (analysisList, pageInfo) => {
    // 총 페이지 수
    // 한 페이지에 들어가는 list
    // 전체list 중 페이지에 들어가는 첫번째 데이터의 인덱스
    // 전체list 중 페이지에 들어가는 마지막 데이터의 인덱스

    let arrangeList = [];
    analysisList?.map(item => {
      const goodsIdList = Object.keys(item.goodsInfoList);
      return (
        goodsIdList.map((goodsId, idx) => {
          return(
            item.goodsInfoList[goodsId].salesCnt !== 0 ?
              arrangeList.push({
                goodsId: goodsId,
                logDt: item.logDt,
                itemNm: item.goodsInfoList[goodsId].itemNm, // goods 이름
                categoryNm: item.goodsInfoList[goodsId].categoryNm, // 카테고리 이름
                salesCnt: item.goodsInfoList[goodsId].salesCnt,
                salesAmt: item.goodsInfoList[goodsId].salesAmt,
                optionList: item.goodsInfoList[goodsId].optionList,
                goodsOpt: item.goodsInfoList[goodsId].goodsOpt,
              })
            :
              arrangeList
          );
        })
      )
    });

    let totalPageCnt = arrangeList?.length % pageInfo?.rowsPerPage > 0 ? Math.floor(arrangeList?.length / pageInfo?.rowsPerPage) + 1 : arrangeList?.length / pageInfo?.rowsPerPage;
    let startIndex = (pageInfo?.curPage - 1) * pageInfo?.rowsPerPage;
    let endIndex = pageInfo?.curPage * pageInfo?.rowsPerPage;
    let sliceList = arrangeList?.slice(startIndex, endIndex);
    
    return {
      totalPageCnt,
      analysisList: sliceList,
      startIndex,
    }
  }
);

const selectAnalysisPageInfo = createDraftSafeSelector(
  (state: any) => state.salesAnalysisLog.pageInfo,
  pageInfo => pageInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: SalesAnalysisState) => state.actionResult,
  (state: SalesAnalysisState) => state.isLoading,
  (state: SalesAnalysisState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);



export const salesAnalysisLogSelector = {
  salesAnalysisLogList : state => selectSalesAnalysisLogList(state),
  salesAnalysisLogFilter : state => selectSalesAnalysisLogFilter(state),
  filterAnalysisList : state => selectFilterAnalysisList(state),
  analysisPageInfo : state => selectAnalysisPageInfo(state),
  status : state => selectStatus([SALESANALYSISLOG])
};

export const SALESANALYSISLOG = slice.name;
export const salesAnalysisLogReducer = slice.reducer;
export const salesAnalysisLogAction = slice.actions;
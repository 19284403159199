import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { salesDailyLogAction} from './salesDailyLogSlice';

import * as salesDailyLogApi from '../lib/salesDailyLogApi';


function* salesDailyLogList({ payload }) {
    try {
      const salesDailyList = yield call(salesDailyLogApi.list, payload);
      yield put(salesDailyLogAction.salesDailyLogListSuccess({ salesDailyList }));
    } catch (err) {
      yield put(salesDailyLogAction.salesDailyLogListFailure('salesDailyLogList List Error'));
    }
  }

  function* salesDailyLogAdd({ payload }) {

    try {
      const result = yield call(salesDailyLogApi.logAdd, payload);
      yield put(salesDailyLogAction.salesDailyLogAddSuccess({ result }));
    } catch (err) {
      yield put(salesDailyLogAction.salesDailyLogAddFailure('salesDailyLogAdd List Error'));
    }
  }




  
  export function* watchSalesDailyLogList() {
    yield takeLatest(salesDailyLogAction.salesDailyLogList, salesDailyLogList);
  }
    
  export function* watchSalesDailyLogAdd() {
    yield takeLatest(salesDailyLogAction.salesDailyLogAdd, salesDailyLogAdd);
  }



  
  
  function* rootSaga() {
    yield all([
      fork(watchSalesDailyLogList),
      fork(watchSalesDailyLogAdd),
    ]);
  }
  
  export default rootSaga;
  
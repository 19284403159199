import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { agentSelector } from '../../modules/agentSlice';
import { shopSelector } from '../../modules/shopSlice';

import someoneIcon from '../../assets/images/common/someone.png';
import eligaIcon from '../../assets/images/common/eliga-sm.png';

const Header = ({ setIsMenuOpen }) => {
  const deviceInfo = useSelector(agentSelector.deviceInfo);
  const shopInfo = useSelector(shopSelector.shopInfo);

  const [time, setTime] = useState('');

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(dayjs().format('YYYY-MM-DD HH:mm:ss'));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <HeaderBox>
      <div className="left">
        <img src={eligaIcon} alt="홈으로" onClick={() => setIsMenuOpen(true)} />
        <span className="pos-number">{deviceInfo.deviceNm}</span>
        <span className="date">{time}</span>
      </div>
      <div className="right">
        <img src={someoneIcon} alt="User Icon" />
        <span>{shopInfo.shopNm}</span>
      </div>
    </HeaderBox>
  );
};

const HeaderBox = styled.header`
  width: 100%;
  height: 72px;
  padding: 20px;
  font-size: 16px;
  background-color: #fff;
  border-bottom: 1px #e5e5e5;
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
  }
  .left {
    color: #000;
    .date {
      margin-left: 20px;
    }
    .pos-number {
      margin-left: 34px;
    }
  }
  .right {
    color: #333;
    img {
      width: 30px;
      height: auto;
      margin-right: 13px;
    }
  }
`;

export default React.memo(Header);

import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface ReportPayload {
  reportStartDt: string;
  reportEndDt: string;
}

interface FilterInfo {
  startDt: string;
  endDt: string;
}

interface PageInfo {
  rowsPerPage: number;
  curPage: number;
}

interface ReportState {
  orderList: Array<>;
  filterInfo: FilterInfo;
  pageInfo: PageInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const ReportInitialState: ReportState = {
  orderList: [],
  filterInfo: { startDt: '', endDt: '', },
  pageInfo: { rowsPerPage: 10, curPage: 1 },
  actionResult: '',
  isLoading: false,
  error: null,
}

const reducers = {
  initReportState: (state: ReportState) => {
    state.orderList = [];
    state.filterInfo = { startDt: '', endDt: '', };
    state.pageInfo = { rowsPerPage: 10, curPage: 1 };
  },
  orderList: (state: ReportState, { payload }: PayloadAction<ReportPayload>) => {
    state.filterInfo.startDt = payload.reportStartDt;
    state.filterInfo.endDt = payload.reportEndDt;
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  orderListSuccess: (state: ReportState, { payload }: PayloadAction<ReportState>) => {
    state.orderList = payload.orderList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  orderListFailure: (state: OrderState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  reportFilter: (state: ReportState, { payload: {reportStartDt, reportEndDt} }: PayloadAction<ReportState>) => {
    state.filterInfo.startDt = reportStartDt;
    state.filterInfo.endDt = reportEndDt;
  },
  reportPaging: (state: ReportState, { payload: {key, value} }: PayloadAction<ReportState>) => {
    state.pageInfo[key] = value;
  },
  actionResultClear: (state: ReportState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'report',
  initialState: ReportInitialState,
  reducers: reducers,
});

const selectOrderList = createDraftSafeSelector(
  (state: any) => state.order.orderList,
  orderList => orderList,
);

const selectReportPageInfo = createDraftSafeSelector(
  (state: any) => state.report.pageInfo,
  pageInfo => pageInfo,
);


const selectStatus = createDraftSafeSelector(
  (state: ReportState) => state.actionResult,
  (state: ReportState) => state.isLoading,
  (state: ReportState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

const selectFilterOrderList = createDraftSafeSelector(
  (state: any) => state.order.orderList,
  (state: any) => state.report.filterInfo,
  (state: any) => state.report.pageInfo,
  (orderList, filterInfo, pageInfo) => {
    // 총 페이지 수
    // 한 페이지에 들어가는 list
    // 전체list 중 페이지에 들어가는 첫번째 데이터의 인덱스
    // 전체list 중 페이지에 들어가는 마지막 데이터의 인덱스

    let totalPageCnt = orderList.length % pageInfo.rowsPerPage > 0 ? Math.floor(orderList.length / pageInfo.rowsPerPage) + 1 : orderList.length / pageInfo.rowsPerPage;
    let startIndex = (pageInfo.curPage - 1) * pageInfo.rowsPerPage;
    let endIndex = pageInfo.curPage * pageInfo.rowsPerPage;
    let sliceList = orderList.slice(startIndex, endIndex);

    return {
      totalPageCnt,
      orderList: sliceList,
      startIndex,
    }
  }
);

export const reportSelector = {
  orderList: state => selectOrderList(state),
  filterOrderList: state => selectFilterOrderList(state),
  reportPageInfo: state => selectReportPageInfo(state),
  status: state => selectStatus([REPORT]),
};

export const REPORT = slice.name;
export const reportReducer = slice.reducer;
export const reportAction = slice.actions;
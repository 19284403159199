import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface PlayerPayload {
  playToken?: string;
  deviceToken?: string;
  playlistId?: string;
}

interface PlayInfo {
  accountId?: string;
  placeId?: string;
  playlistId?: string;
}

interface PlaylistInfo {
  playlistId?: string;
  playlistDt?: string;
}

interface PlayerState {
  playInfo: PlayInfo;
  playlistInfo: PlaylistInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const playerInitialState: PlayerState = {
  playInfo: {},
  playlistInfo: {},
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  token: (state: PlayerState, { payload }: PayloadAction<PlayerPayload>) => {
    state.actionResult = 'TOKEN_REQ';
    state.isLoading = true;
    state.error = null;
  },
  tokenSuccess: (
    state: PlayerState,
    { payload }: PayloadAction<PlayerState>
  ) => {
    state.playInfo = payload.playInfo;
    state.actionResult = 'TOKEN_OK';
    state.isLoading = false;
    state.error = null;
  },
  tokenFailure: (state: PlayerState, action: PayloadAction<string>) => {
    state.actionResult = 'TOKEN_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  playlist: (state: PlayerState, { payload }: PayloadAction<PlayerPayload>) => {
    state.actionResult = 'PLAYLIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  playlistSuccess: (
    state: PlayerState,
    { payload }: PayloadAction<PlayerState>
  ) => {
    state.playlistInfo = payload.playlistInfo;
    state.actionResult = 'PLAYLIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  playlistFailure: (state: PlayerState, action: PayloadAction<string>) => {
    state.actionResult = 'PLAYLIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: PlayerState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'player',
  initialState: playerInitialState,
  reducers: reducers,
});

const selectPlayInfo = createDraftSafeSelector(
  (state: PlayerState) => state.playInfo,
  (playInfo) => playInfo
);

const selectPlaylistInfo = createDraftSafeSelector(
  (state: PlayerState) => state.playlistInfo,
  (playlistInfo) => playlistInfo
);

const selectStatus = createDraftSafeSelector(
  (state: PlayerState) => state.actionResult,
  (state: PlayerState) => state.isLoading,
  (state: PlayerState) => state.error,
  (actionResult, isLoading, error) => ({
    actionResult,
    isLoading,
    error,
  })
);

export const playerSelector = {
  playInfo: (state) => selectPlayInfo(state[PLAYER]),
  playlistInfo: (state) => selectPlaylistInfo(state[PLAYER]),
  status: (state) => selectStatus(state[PLAYER]),
};

export const PLAYER = slice.name;
export const playerReducer = slice.reducer;
export const playerAction = slice.actions;

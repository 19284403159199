import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { playerAction } from './playerSlice';

import * as playerApi from '../lib/playerApi';

function* token({ payload }) {
  try {
    const playInfo = yield call(playerApi.token, payload);
    yield put(playerAction.tokenSuccess({ playInfo }));
  } catch (err) {
    yield put(playerAction.tokenFailure('Playlist Error'));
  }
}

function* playlist({ payload }) {
  try {
    const playlistInfo = yield call(playerApi.playlist, payload);
    yield put(playerAction.playlistSuccess({ playlistInfo }));
  } catch (err) {
    yield put(playerAction.playlistFailure('Playlist Error'));
  }
}

export function* watchToken() {
  yield takeLatest(playerAction.token, token);
}

export function* watchPlaylist() {
  yield takeLatest(playerAction.playlist, playlist);
}

function* rootSaga() {
  yield all([
    fork(watchToken),
    fork(watchPlaylist),
  ]);
}

export default rootSaga;

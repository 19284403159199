import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { shopAction } from '../../modules/shopSlice';

import { openLogSelector,openLogAction } from '../../modules/openLogSlice';



const ShopInfo = ({ deviceId,shopId }) => {
  const dispatch = useDispatch();

  /*
  useEffect (() => {
    dispatch(
      openLogAction.openLogInfo({
        shopId: shopId,
        // openStartDt:  dayjs().subtract(1,'month').format('YYYYMMDD'),
        // openEndDt:  dayjs().format('YYYYMMDD'),
      }),
      );
    
    },[dispatch,shopId]);

    */





  return <></>
};

export default React.memo(ShopInfo);



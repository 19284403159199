import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goodsAction, goodsSelector } from '../modules/goodsSlice';
import { orderSelector } from '../modules/orderSlice';
import { shopSelector } from '../modules/shopSlice';
import { addLabelCount, getStorageLabelUse, orderLabelText } from './labelPrintUtil';

const useLabelPrint = () => {
  const dispatch = useDispatch();

  const { brandId, shopId, currentOpenDt } = useSelector(shopSelector.shopInfo);
  const { categoryList } = useSelector(goodsSelector.pageCategoryList);
  const selectedOrder = useSelector(orderSelector.selectedCurrentOpenOrder);
  const [printCategoryIds, setPrintCategoryIds] = useState([]);

  const [isLabelPrintModal, setIsLabelPrintModal] = useState(false);
  const [checkItems, setCheckItems] = useState([]);

  useEffect(() => {
    if (categoryList) {
      setPrintCategoryIds(
        categoryList.filter(categoey => categoey.useLabelPrintYn === 'Y').map(item => item.categoryId),
      );
    }
  }, [categoryList]);

  useEffect(() => {
    if (!brandId) {
      return;
    }
    dispatch(goodsAction.categoryList({ brandId }));
  }, [dispatch, brandId]);

  const labelPrintOpen = async () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'REQ_LABEL_PRINTER_OPEN' }));
    }
  };

  const labelPrintClose = async () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'REQ_LABEL_PRINTER_CLOSE' }));
    }
  };

  const labelPrintCheck = async () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'REQ_LABEL_PRINTER_INFO' }));
    }
  };

  const labelPrintCut = async cutYn => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'REQ_LABEL_PRINTER_CUT', cutYn: cutYn }));
    }
  };

  const labelPrint = async textList => {
    if (!getStorageLabelUse()) {
      alert('라벨 프린터 사용 설정이 되지 않았습니다.');
      return;
    }
    if (window.ReactNativeWebView) {
      const openDt = dayjs(currentOpenDt).format('YYYY-MM-DD-HH-mm');
      addLabelCount(openDt);
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'REQ_LABEL_PRINTER_PRINT', data: textList }));
    }
  };

  const testOrderData = useMemo(
    () => ({
      customerNm: '주문자 명',
      customerEngNm: 'CUSTOMER NAME',
      billNo: '주문번호',
      itemTotal: '총건',
      itemIndex: '순서',
      itemNm: '상품명',
      goodsOpt: '필수옵션',
      optNm: ['Option1', 'Option2', 'Option3', 'Option4', 'Option5'],
      orderWaitDt: '주문시간',
      shopNm: '매장명',
      takeType: '픽업유형',
      currentOpenDt: currentOpenDt ? dayjs(currentOpenDt).format('YYYY년 MM월 DD일 HH:mm:ss') : '',
    }),
    [currentOpenDt],
  );

  // const testOrderData = useMemo(
  //   () => ({
  //     customerNm: '김 에스큐아이소프트가나다',
  //     customerEngNm: 'KIM SQISOFT ENG NAME 이름이다이름',
  //     billNo: 'APP_9999',
  //     itemTotal: '11',
  //     itemIndex: '9',
  //     itemNm: '[벙커]코스타라카 푸에고 ICED 입니다 맛있게',
  //     goodsOpt: 'ICED',
  //     optNm: [
  //       '두유변경',
  //       '얼음조금',
  //       '텀블러 할인',
  //       '빨대 2개',
  //       '빨대 2개',
  //       '빨대 2개',
  //       '파우더 조금',
  //       '파우더 조금',
  //       '파우더 조금',
  //       '파우더 조금',
  //       '파우더 조금',
  //       '파우더 조금',
  //       '파우더 조금',
  //       '파우더 조금',
  //       '파우더 조금',
  //       '파우더 조금',
  //       '파우더 조금',
  //       '파우더 조금',
  //     ],
  //     orderWaitDt: '2013년 12월21일 13:50:00',
  //     shopNm: 'SQISOFT 문래사옥 카페',
  //     takeType: 'IN',
  //     currentOpenDt: currentOpenDt ? dayjs(currentOpenDt).format('YYYY년 MM월 DD일 HH:mm:ss') : '',
  //   }),
  //   [currentOpenDt],
  // );

  const orderItems = useMemo(() => {
    const filterItemList = selectedOrder?.itemList?.filter(item => printCategoryIds.includes(item.categoryId)) || [];
    const totalItemQty = filterItemList.reduce((total, item) => total + (item?.itemQty ?? 0), 0);

    let itemCounter = 0;
    return filterItemList.reduce((acc, item) => {
      for (let i = 0; i < item?.itemQty ?? 0; i++) {
        itemCounter += 1;
        acc.push({
          customerNm: selectedOrder?.customerName || '',
          customerEngNm: selectedOrder?.customerEngNm || '',
          billNo: selectedOrder?.billNo || '',
          itemTotal: totalItemQty,
          itemIndex: itemCounter,
          itemNm: item?.itemNm || ' ',
          goodsOpt: item?.goodsOpt || '',
          optNm: item?.optionList?.map(option => option.optNm),
          orderWaitDt: selectedOrder?.orderWaitDt
            ? dayjs(selectedOrder?.orderWaitDt).format('YYYY년 MM월 DD일 HH:mm:ss')
            : '',
          shopNm: selectedOrder?.shopNm,
          takeType: selectedOrder?.takeType,
          currentOpenDt: '',
          stockId: item.stockId,
          itemId: item.stockId + '_' + item?.optionList?.map(option => option.optId).join('-'),
        });
      }
      return acc;
    }, []);
  }, [selectedOrder, printCategoryIds]);

  const labelTestPrint = async () => {
    const openDt = dayjs(currentOpenDt).format('YYYY-MM-DD-HH-mm');
    const labelText = orderLabelText(testOrderData, true, openDt);
    console.log(labelText);

    //프린터 출력
    labelPrint(labelText).catch(err => {
      console.log(err);
    });
  };

  const labelPrintOrder = async () => {
    const openDt = dayjs(currentOpenDt).format('YYYY-MM-DD-HH-mm');
    const sortOrderItems = orderItems.sort((a, b) => b.itemIndex - a.itemIndex);

    if (sortOrderItems.length > 0) {
      for (const item of sortOrderItems) {
        const labelText = orderLabelText(item, false, openDt);

        // console.table(labelText);
        labelPrint(labelText).catch(err => {
          console.log(err);
        });
      }
    } else {
      alert('출력 가능한 제품이 없습니다.');
    }
  };

  const repeatLabelOrderPrint = async () => {
    const openDt = dayjs(currentOpenDt).format('YYYY-MM-DD-HH-mm');
    const filterItems = orderItems
      .filter(order => checkItems.includes(order.itemId))
      .sort((a, b) => b.itemIndex - a.itemIndex);
    if (filterItems.length > 0) {
      for (const item of filterItems) {
        const labelText = orderLabelText(item, false, openDt);

        // console.table(labelText);
        labelPrint(labelText).catch(err => {
          console.log(err);
        });
      }
    } else {
      alert('출력할 제품을 선택하세요.');
    }
  };

  const repeatLabelOrder = async () => {
    if (selectedOrder?.itemList?.length > 1) {
      setIsLabelPrintModal(true);
    } else {
      labelPrintOrder();
    }
  };

  useEffect(() => {
    console.log(checkItems);
  }, [checkItems]);

  return {
    labelPrintOpen,
    labelPrintClose,
    labelPrintCheck,
    labelTestPrint,
    labelPrintOrder,
    labelPrintCut,
    repeatLabelOrder,
    isLabelPrintModal,
    setIsLabelPrintModal,
    checkItems,
    setCheckItems,
    printCategoryIds,
    repeatLabelOrderPrint,
  };
};

export default useLabelPrint;

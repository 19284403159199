import { all, fork, call, put, takeLatest, select } from 'redux-saga/effects';
import { salesAnalysisLogAction, salesAnalysisLogSelector} from './salesAnalysisLogSlice';

import * as salesAnalysisLogApi from '../lib/salesAnalysisLogApi';


function* salesAnalysisLogList({ payload: { shopId } }) {
    try {
      const { startDt, endDt} = yield select(salesAnalysisLogSelector.salesAnalysisLogFilter);

      const data = {
        shopId: shopId,
        startDt: startDt,
        endDt: endDt,
      };

      const analysisList = yield call(salesAnalysisLogApi.list, data);
      yield put(salesAnalysisLogAction.salesAnalysisLogListSuccess({ analysisList }));
    } catch (err) {
      yield put(salesAnalysisLogAction.salesAnalysisLogListFailure('salesAnalysisLogList List Error'));
    }
  }

  function* salesAnalysisLogAdd({ payload }) {
    try {
      const analysisInfo = yield call(salesAnalysisLogApi.logAdd, payload);
      yield put(salesAnalysisLogAction.salesAnalysisLogAddSuccess({ analysisInfo }));
    } catch (err) {
      yield put(salesAnalysisLogAction.salesAnalysisLogAddFailure('salesAnalysisLogAdd List Error'));
    }
  }




  
  export function* watchSalesAnalysisLogList() {
    yield takeLatest(salesAnalysisLogAction.salesAnalysisLogList, salesAnalysisLogList);
  }
  
  
  export function* watchSalesAnalysisLogAdd() {
      yield takeLatest(salesAnalysisLogAction.salesAnalysisLogAdd, salesAnalysisLogAdd);

    }

  
  
  function* rootSaga() {
    yield all([
      fork(watchSalesAnalysisLogList),
      fork(watchSalesAnalysisLogAdd),
    ]);
  }
  
  export default rootSaga;
  
import { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from "connected-react-router";

import { ReactComponent as IconError } from '../../assets/images/error/icon-error.svg';
import Button from '../../components/Button';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
    }
  }

  componentDidCatch(error, info) {
    this.setState({
      error: true,
    });
  }

  refresh() {
    window.location.reload();
  }

  goHome() {
    this.props.push('/home');
    this.setState({
      error: false,
    });
  }

  render() {
    if (this.state.error) {
      return (
        <Error>
          <IconError />
          <span className="comment-service-not">
            서비스에 접속할 수 없습니다.
          </span>
          <span className="comment-sorry">
            페이지를 로드하는 도중에 오류가 발생하였습니다.<br/>
            서비스 이용에 불편을 드려 죄송합니다.
          </span>
          <div className="button-area">
            <StyledButton color="red" outline onClick={this.refresh.bind(this)}>새로고침</StyledButton>
            <StyledButton color="gray" outline onClick={this.goHome.bind(this)}>홈으로</StyledButton>
          </div>
        </Error>
      )
    }
    return this.props.children;
  }
}

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
});

const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #F5F6F8;
  font-size: 40px;

  .comment-service-not {
    margin-top: 30px;
    font-family: Noto Sans KR;
    font-weight: 500;
    font-size: 22px;
    color: #000000;
  }

  .comment-sorry {
    margin-top: 10px;
    font-family: Noto Sans KR;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    text-align: center;
    line-height: 1.4;
  }

  .button-area {
    margin-top: 30px;
  }
`;

const StyledButton = styled(Button)`

  border-radius: 5px;
  width: 120px;
  height: 40px;
  font-size: 16px;
`;

export default connect(mapStateToProps, { push })(ErrorBoundary);

import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface CategoryInfo {
  categoryId: string;
  categoryPid: string;
  spaceId: string;
  brandId: string;
  categoryNm: string;
  regId: string;
  regDt: string;
  modId: string;
  modDt: string;
  useYn: string;
  delYn: string;
  delDt: string;
}

interface GoodsState {
  categoryList: Array<CategoryInfo>;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const initialState: GoodsState = {
  categoryList: [],
  menuList: [],
  goodsList: [],
  goodsInfo: {},
  goodsFilterInfo: {
    selectValue: '',
    searchValue: ''
  },
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  actionResultClear: (state: GoodsState) => {
    state.actionResult = '';
  },
  goodsStateClear: (state: GoodsState) => {
    state.categoryList = [];
    state.menuList = [];
    state.goodsList = [];
    state.goodsInfo = {};
    state.goodsFilterInfo = {
      selectValue: '',
      searchValue: ''
    };
    state.actionResult = '';
    state.isLoading = false;
    state.error = null;
  },
  categoryList: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'CATEGORY_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  categoryListSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.categoryList = payload.categoryList;
    state.actionResult = 'CATEGORY_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  categoryListFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CATEGORY_LIST_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  categoryUpdate: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'CATEGORY_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  categoryUpdateSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.categoryList = payload.categoryList.filter(category => category.delYn !== 'Y');
    state.categoryListAll = payload.categoryList;
    state.actionResult = 'CATEGORY_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  categoryUpdateFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CATEGORY_UPDATE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  goodsFilter(state: OptionState, { payload }: PayloadAction<OptionPayload>) {
    state.goodsFilterInfo.selectValue = payload.selectValue;
    state.goodsFilterInfo.searchValue = payload.searchValue;
  },
  goodsList: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'GOODS_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  goodsListSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.goodsList = payload.goodsList;
    state.actionResult = 'GOODS_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  goodsListFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'GOODS_LIST_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  goodsDetail: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'GOODS_DETAIL_REQ';
    state.isLoading = true;
    state.error = null;
  },
  goodsDetailSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.goodsInfo = payload.goodsInfo;
    state.actionResult = 'GOODS_DETAIL_OK';
    state.isLoading = false;
    state.error = null;
  },
  goodsDetailFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'GOODS_DETAIL_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  goodsEdit: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'GOODS_EDIT_REQ';
    state.isLoading = true;
    state.error = null;
  },
  goodsEditSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.goodsList = payload.goodsList;
    state.actionResult = 'GOODS_EDIT_OK';
    state.isLoading = false;
    state.error = null;
  },
  goodsEditFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'GOODS_EDIT_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  stockUpdate: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'STOCK_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  stockUpdateSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.goodsList = payload.goodsList;
    state.actionResult = 'STOCK_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  stockUpdateFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'STOCK_UPDATE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  changeDisplayAll: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'CHANGE_DISPLAY_ALL_REQ';
    state.isLoading = true;
    state.error = null;
  },
  changeDisplayAllSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.goodsList = payload.goodsList;
    state.actionResult = 'CHANGE_DISPLAY_ALL_OK';
    state.isLoading = false;
    state.error = null;
  },
  changeDisplayAllFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CHANGE_DISPLAY_ALL_ERR';
    state.isLoading = false;
    state.error = payload;
  },
};

const slice = createSlice({
  name: 'goods',
  initialState,
  reducers,
});

const selectCategoryList = createDraftSafeSelector(
  (state: GoodsState) => state.categoryList,
  categoryList => categoryList,
);

// const selectMenuListByCategory = createDraftSafeSelector(
//   (state: any) => state.shop.shopInfo,
//   (state: any) => state.goods.categoryList,
//   (state: any) => state.goods.menuList,
//   (shopInfo, categoryList, menuList) => {
//     const mainCategoryList = categoryList.filter(category => category.categoryPid === shopInfo.brandId);

//     let newCategoryList = [];
//     for (const mainCategory of mainCategoryList) {
//       let newMenuList = [];
//       const subCategoryList = categoryList.filter(subCategory => subCategory.categoryPid === mainCategory.categoryId && subCategory.useYn === 'Y');
//       for (const subCategory of subCategoryList) {
//         const filterMenuList = menuList.filter(menu => menu.categoryId === subCategory.categoryId);
//         for (const filterMenu of filterMenuList) {
//           newMenuList.push({
//             ...subCategory,
//             ...filterMenu,
//           });
//         }
//       }

//       newCategoryList.push({
//         ...mainCategory,
//         menuList: newMenuList,
//       })
//     }

//     return newCategoryList;
//   }
// );

const selectPageCategoryList = createDraftSafeSelector(
  (state: GoodsState) => state.categoryList,
  (state: GoodsState) => state.goodsList,

  (categoryList, goodsList) => {
    let sliceList = [...categoryList].sort((l, r) => {
      return l.categoryOrder === r.categoryOrder ? 0 : l.categoryOrder < r.categoryOrder ? -1 : 1;
    });
    // 카테고리 안에 등록상품 join
    if (goodsList.length > 0) {
      sliceList = sliceList.map(list => {
        let goodsCnt = 0;
        let parentGoodsList = goodsList.filter(goods => goods.goodsPid === goods.brandId); // 상품 최상위
        goodsCnt += parentGoodsList.filter(goods => goods.categoryId === list.categoryId).length;

        return { ...list, goodsCnt };
      });
    }

    return {
      categoryList: sliceList,
    };
  },
);

const selectGoodsList = createDraftSafeSelector(
  (state: GoodsState) => state.goodsList,
  goodsList => goodsList,
);

const selectJoinGoodsList = createDraftSafeSelector(
  (state: GoodsState) => state.goods.goodsList,
  (state: any) => state.shop.shopInfo,
  (state: GoodsState) => state.goods.goodsFilterInfo,
  (goodsList, shopInfo, goodsFilterInfo) => {
    const mainGoodsList = goodsList.filter(goods => goods.goodsPid === shopInfo.brandId && goods.useYn === 'Y');
    const subGoodsList = goodsList.filter(goods => goods.goodsPid !== shopInfo.brandId);

    let joinGoodsList = [];
    for (let mainGoods of mainGoodsList) {
      joinGoodsList.push({
        ...mainGoods,
        goodsList: [],
      });
    }

    for (let joinGoods of joinGoodsList) {
      joinGoods.goodsList = subGoodsList.filter(subGoods => subGoods.goodsPid === joinGoods.goodsId);
    }

    // 대표상품이 없는 상품은 useYn을 N으로 변경
    for (const joinGoods of joinGoodsList) {
      if (joinGoods.goodsType === 'PACKAGE' && !joinGoods.goodsList.find(subGoods => subGoods.mainYn === 'Y')) {
        joinGoods.useYn = 'N';
      }
    }

    let filteredGoodsList = joinGoodsList.filter(joinGoods => joinGoods.useYn === 'Y');
    if (goodsFilterInfo.selectValue || goodsFilterInfo.searchValue) {
      filteredGoodsList = joinGoodsList.filter(joinGoods => {
        let searchSelectFilter = true;
        let goodsNmFilter = true;
        let goodsEngNmFilter = true;

        if (goodsFilterInfo.selectValue) {
          switch (goodsFilterInfo.selectValue) {
            case 'displayY':
              searchSelectFilter = joinGoods.displayYn === 'Y';
              break;
            case 'displayN':
              searchSelectFilter = joinGoods.displayYn === 'N';
              break;
            case 'soldOutY':
              searchSelectFilter = joinGoods.soldOutYn === 'Y';
              break;
            default:
              break;
          }
        }

        if (goodsFilterInfo.searchValue) {
          goodsNmFilter = joinGoods.goodsNm?.toLowerCase().includes(goodsFilterInfo.searchValue.toLowerCase());
        }
        if (goodsFilterInfo.searchValue) {
          goodsEngNmFilter = joinGoods.goodsEngNm?.toLowerCase().includes(goodsFilterInfo.searchValue.toLowerCase());
        }

        return searchSelectFilter && (goodsNmFilter || goodsEngNmFilter);
      });
    }

    return filteredGoodsList;
  }
);

const selectGoodsInfo = createDraftSafeSelector(
  (state: GoodsState) => state.goodsInfo,
  goodsInfo => goodsInfo
);

const selectStatus = createDraftSafeSelector(
  (state: GoodsState) => state.actionResult,
  (state: GoodsState) => state.isLoading,
  (state: GoodsState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const goodsSelector = {
  pageCategoryList: state => selectPageCategoryList(state[GOODS]),
  categoryList: state => selectCategoryList(state[GOODS]),
  goodsList: state => selectGoodsList(state[GOODS]),
  joinGoodsList: state => selectJoinGoodsList(state),
  goodsInfo: state => selectGoodsInfo(state[GOODS]),
  status: state => selectStatus(state[GOODS]),
};

export const GOODS = slice.name;
export const goodsReducer = slice.reducer;
export const goodsAction = slice.actions;

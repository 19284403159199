import { all, fork, call, put, takeEvery, select } from 'redux-saga/effects';
import { orderAction, orderSelector } from './orderSlice';

import * as orderApiLib from '../lib/orderApi';
import * as robotApiLib from '../lib/robotApi';
import { shopSelector } from './shopSlice';
import dayjs from 'dayjs';

function* orderList({ payload }) {
  try {
    const { orderStartDt, orderEndDt } = yield select(orderSelector.payloadOrderDt);
    const orderList = yield call(orderApiLib.list, { ...payload, orderStartDt, orderEndDt });

    yield put(orderAction.orderListSuccess({ orderList }));
  } catch (err) {
    console.log(`orderList Saga Err`, err);
    yield put(orderAction.orderListFailure('Order List Error'));
  }
}

function* orderOpenList({ payload }) {
  try {
    const { orderStartDt, orderEndDt } = yield select(orderSelector.payloadOrderDt);
    const orderList = yield call(orderApiLib.openList, { ...payload, orderStartDt, orderEndDt });

    yield put(orderAction.orderOpenListSuccess({ orderList }));
  } catch (err) {
    console.log(`orderOpenList Saga Err`, err);
    yield put(orderAction.orderOpenListFailure('order OpenList Error'));
  }
}

function* currentOpenOrderList({ payload }) {
  try {
    const { currentOpenDt } = yield select(shopSelector.shopInfo);

    let currentOpenOrderList = [];
    if (currentOpenDt) {
      currentOpenOrderList = yield call(orderApiLib.openList, {
        ...payload,
        orderStartDt: dayjs(currentOpenDt).startOf('day').toISOString(),
        orderEndDt: dayjs().endOf('day').toISOString(),
      });
    }

    yield put(orderAction.currentOpenOrderListSuccess({ currentOpenOrderList: currentOpenOrderList || [] }));
  } catch (err) {
    console.log(`currentOpenOrderList Saga Err`, err);
    yield put(orderAction.currentOpenOrderListFailure('Order List Error'));
  }
}

function* updateOrder({ payload }) {
  try {
    yield call(orderApiLib.update, payload);

    const { shopId } = yield select(shopSelector.shopInfo);
    const { orderStartDt, orderEndDt } = yield select(orderSelector.payloadOrderDt);
    const orderList = yield call(orderApiLib.list, { shopId, orderStartDt, orderEndDt });
    yield put(orderAction.orderListSuccess({ orderList }));
  } catch (err) {
    yield put(orderAction.orderListFailure('Order List Error'));
  }
}

function* regOrder({ payload }) {
  try {
    yield call(orderApiLib.regOrder, payload);

    yield put(orderAction.regOrderSuccess(payload));
  } catch (err) {
    yield put(orderAction.regOrderFailure({ orderId: payload.orderId, error: 'Reg Order Error' }));
  }
}

function* regOrderRobot({ payload }) {
  try {
    /*const result = yield call(robotApiLib.accept, payload);
    if (result.resultFlag) {
      payload.robotName = result.data?.data?.robot_nickname ?? '';
      yield call(orderApiLib.regOrder, payload);
      yield put(orderAction.regOrderRobotSuccess(payload));
    } else {
      yield put(
        orderAction.regOrderRobotFailure({
          orderId: payload.orderId,
          error: 'robot order fail',
          errorname: result.data?.data?.code,
        }),
      );
    }*/
    //로봇 배차 확정을 고객이 주문할때 처리하도록 변경하면서 로봇 배차 확정을 주석처리함
    yield call(orderApiLib.regOrder, payload);
    yield put(orderAction.regOrderRobotSuccess(payload));
  } catch (err) {
    yield put(orderAction.regOrderRobotFailure({ orderId: payload.orderId, error: 'robot order fail', errorname: '' }));
  }
}

function* takeOrder({ payload }) {
  try {
    yield call(orderApiLib.takeOrder, payload);

    yield put(orderAction.takeOrderSuccess(payload));
  } catch (err) {
    console.log(err);
    yield put(orderAction.takeOrderFailure({ orderId: payload.orderId, error: 'Take Order Error' }));
  }
}
function* takeOrderRobot({ payload }) {
  try {
    yield call(orderApiLib.takeOrderRobot, payload);

    yield put(orderAction.takeOrderRobotSuccess(payload));
  } catch (err) {
    console.log(err);
    yield put(orderAction.takeOrderRobotFailure({ orderId: payload.orderId, error: 'Take Order Error' }));
  }
}

function* saleOrderList({ payload }) {
  try {
    yield call(orderApiLib.saleOrderList, payload);

    yield put(orderAction.saleOrderListSuccess(payload));
  } catch (err) {
    yield put(orderAction.saleOrderListFailure({ orderId: payload.orderIdList, error: 'Sale Order List Error' }));
  }
}

function* cancelOrderList({ payload }) {
  try {
    yield call(orderApiLib.cancelOrderList, payload);

    yield put(orderAction.cancelOrderListSuccess(payload));
  } catch (err) {
    yield put(orderAction.cancelOrderListFailure({ orderId: payload.orderIdList, error: 'cancelOrderList Error' }));
  }
}

function* returnOrder({ payload }) {
  try {
    const newOrder = yield call(orderApiLib.returnOrder, payload);

    yield put(orderAction.returnOrderSuccess({ newOrder }));
  } catch (error) {
    yield put(orderAction.returnOrderFailure({ error }));
  }
}

export function* watchOrderList() {
  yield takeEvery(orderAction.orderList, orderList);
}

export function* watchorderOpenList() {
  yield takeEvery(orderAction.orderOpenList, orderOpenList);
}

export function* watchCurrentOpenOrderList() {
  yield takeEvery(orderAction.currentOpenOrderList, currentOpenOrderList);
}

export function* watchUpdateOrder() {
  yield takeEvery(orderAction.updateOrder, updateOrder);
}

export function* watchRegOrder() {
  yield takeEvery(orderAction.regOrder, regOrder);
}
export function* watchRegOrderRobot() {
  yield takeEvery(orderAction.regOrderRobot, regOrderRobot);
}

export function* watchTakeOrder() {
  yield takeEvery(orderAction.takeOrder, takeOrder);
}

export function* watchTakeOrderRobot() {
  yield takeEvery(orderAction.takeOrderRobot, takeOrderRobot);
}

export function* watchSaleOrderList() {
  yield takeEvery(orderAction.saleOrderList, saleOrderList);
}

export function* watchCancelOrderList() {
  yield takeEvery(orderAction.cancelOrderList, cancelOrderList);
}

export function* watchReturnOrder() {
  yield takeEvery(orderAction.returnOrder, returnOrder);
}

function* rootSaga() {
  yield all([
    fork(watchOrderList),
    fork(watchorderOpenList),
    fork(watchCurrentOpenOrderList),
    fork(watchUpdateOrder),
    fork(watchRegOrder),
    fork(watchRegOrderRobot),
    fork(watchTakeOrder),
    fork(watchTakeOrderRobot),
    fork(watchSaleOrderList),
    fork(watchCancelOrderList),
    fork(watchReturnOrder),
  ]);
}

export default rootSaga;

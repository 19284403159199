import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface SettingState {
  alarmInfo: {
    useNewOrderAlarmYn: string,
    usePickUpAlarmYn: string,
    useNoCheckOrderAlarmYn: string,
    useReservationOrderAlarmYn: string,
    useNoCheckReservationOrderAlarmYn: string,
  },
  actionResult : string,
  isLoading: boolean,
  error: string | null,
}

const settingInitialState: SettingState = {
  alarmInfo: {
    useNewOrderAlarmYn: 'Y',
    usePickUpAlarmYn: 'Y',
    useNoCheckOrderAlarmYn: 'Y',
    useReservationOrderAlarmYn: 'Y',
    useNoCheckReservationOrderAlarmYn: 'Y',
  },
  actionResult : '',
  isLoading: false,
  error: null,
};


const reducers = {
  toogleAlarm: (state: SettingState, { payload }: PayloadAction<SettingState>) => {
    state.alarmInfo = { ...state.alarmInfo, [payload.key]: payload.value };
  },
};

const slice = createSlice({
  name: 'setting',
  initialState : settingInitialState,
  reducers: reducers,
});

const selectAlarmInfo = createDraftSafeSelector(
  (state : SettingState) => state.alarmInfo,
  (alarmInfo) => alarmInfo,
);

export const settingSelector = {
  alramInfo: state => selectAlarmInfo(state[SETTING]),
};

export const SETTING = slice.name;
export const settingReducer = slice.reducer;
export const settingAction = slice.actions;

import dayjs from 'dayjs';
import * as apiLib from './api';

export const pairing = async ({ pairingId, machineId }) => {
  try {
    const data = {
      pairingId,
      machineId,
      ipAddress: 'BROWSER',
      macAddress: 'BROWSER',
    };

    const result = await apiLib.fetchStoreApi('/agent/pairing', data);
    if (result.resultFlag) {
      localStorage.setItem('deviceId', result.deviceId);
      localStorage.setItem('licenseKey', result.licenseKey);

      return {
        deviceId: result.deviceId,
        licenseKey: result.licenseKey,
      };
    } else {
      throw Object.assign(new Error('pairing error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const licenseCheck = async ({ licenseKey, deviceId, machineId }) => {
  try {
    const data = {
      licenseKey,
      deviceId,
      machineId,
      ipAddress: 'BROWSER',
      macAddress: 'BROWSER',
    };

    const result = await apiLib.fetchStoreApi('/agent/license-info', data);
    if (result.resultFlag) {
      localStorage.setItem('deviceToken', result.deviceToken);
      return result.deviceToken;
    } else {
      throw Object.assign(new Error('token error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const deviceManage = async ({ deviceToken, deviceInfo }) => {
  try {
    const navigatorInfo = window.navigator;
    const screenInfo = window.screen;

    const modelNm = deviceInfo ? `${deviceInfo.model}` : `${navigatorInfo.appCodeName} ${navigatorInfo.appName}`;
    const osVer = deviceInfo ? `${deviceInfo.manufacturer} ${deviceInfo.systemName} ${deviceInfo.systemVersion}` : `${navigatorInfo.platform} ${navigatorInfo.product} ${navigatorInfo.productSub}`;
    const displayCount = '';
    const displayWidth = screenInfo.width;
    const displayHeight = screenInfo.height;
    const displayRotation = screenInfo.orientation;

    const data = {
      deviceToken,
      modelNm,
      osVer,
      agentVer: deviceInfo ? deviceInfo.appVersion : '',
      cpuCount: 0,
      cpuUse: 0,
      memSize: deviceInfo ? deviceInfo.totalMemory : 0,
      memUse: deviceInfo ? deviceInfo.usedMemory : 0,
      hddInfo: deviceInfo ? [ {T: (deviceInfo.capacity / 1000000).toFixed(0), U: ((deviceInfo.capacity - deviceInfo.freeDiskStorage) / 1000000).toFixed(0)} ] : [],
      macAddr: deviceInfo ? deviceInfo.mac : '',
      ipAddrV4: deviceInfo ? deviceInfo.ip : '',
      ipAddrV6: '',
      gmtZone: dayjs().utcOffset(),
      powerUpTime: deviceInfo ? dayjs().subtract(deviceInfo.startupTime, 'milliseconds').format() : '',
      powerDownTime: deviceInfo ? dayjs.unix(deviceInfo.lastUpdateTime / 1000).format() : '',
      soundVolume: 0,
      soundMute: 0,
      displayStatus: 1,
      displayCount,
      displayWidth,
      displayHeight,
      displayRotation,
    };

    const result = await apiLib.fetchStoreApi('/agent/device-manage', data);
    if (result.resultFlag) {
      return result.manageInfo;
    } else {
      throw Object.assign(new Error('token error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const scrshotUpload = async ({ deviceToken, deviceId, base64Data }) => {
  try {
    const sliceSize = 512;
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blobData = new Blob(byteArrays, { type: 'image/jpeg' });

    const uploadData = new FormData();
    uploadData.append('deviceToken', deviceToken);
    uploadData.append('deviceId', deviceId);
    uploadData.append('scrshotFile', blobData, {
      filename: 'screen.jpg',
    });

    const result = await apiLib.fetchStoreFormDataApi(
      '/agent/scrshot',
      uploadData,
    );
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('scrshot upload error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import dayjs from 'dayjs';
import { openLogAction } from './openLogSlice';

import * as openLogApi from '../lib/openLogApi';


function* openLogList({ payload }) {

  console.log('payload',payload);
    try {
      const openLogList = yield call(openLogApi.list, payload);
      yield put(openLogAction.openLogListSuccess({ openLogList }));
    } catch (err) {
      yield put(openLogAction.openLogListFailure('OpenLog List Error'));
    }
  }

  function* openLogLogAdd({ payload }) {
    console.log('payload',payload);
    try {
      console.log('addlog 요청');
      
      const openLog = yield call(openLogApi.logAdd, payload);
      console.log('add로그 요청');
      // const openLogList = yield call(openLogApi.list, payload);

      //yield put(openLogAction.openLogLogAddSuccess({ openLog,openLogList }));
      yield put(openLogAction.openLogLogAddSuccess({ openLog }));
    } catch (err) {
      yield put(openLogAction.openLogLogAddFailure('OpenLog List Error'));
    }
  }

  // function* openLogInfo({ payload }) {
    
  //   payload.openStartDt = dayjs().subtract(1,'month').format('YYYYMMDD');
  //   payload.openEndDt = dayjs().format('YYYYMMDD');

  //     try {
  //       const openLogList = yield call(openLogApi.list, payload);
  //       yield put(openLogAction.openLogInfoSuccess({ openLogList }));
  //     } catch (err) {
  //       yield put(openLogAction.openLogInfoFailure('openLogInfo Error'));
  //     }
  //   }




  
  export function* watchOpenLogList() {
    yield takeLatest(openLogAction.openLogList, openLogList);
  }

  export function* watchOpenLogLogAdd() {
    yield takeLatest(openLogAction.openLogLogAdd, openLogLogAdd);
  }
  // export function* watchOpenLogInfo() {
  //   yield takeLatest(openLogAction.openLogInfo, openLogInfo);
  // }

  
  
  function* rootSaga() {
    yield all([
      fork(watchOpenLogList),
      fork(watchOpenLogLogAdd),
      // fork(watchOpenLogInfo),
    ]);
  }
  
  export default rootSaga;
  
import * as apiLib from './api';

export const list = async ({ shopId, startDt, endDt }) => {

    try {
      const data = {
        shopId,
        startDt,
        endDt,
      };
  
      const result = await apiLib.fetchStoreApi('/pos/sales-month/list', data);
      if (result.resultFlag) {
        return result.salesMonthList;
      } else {
        throw Object.assign(new Error('sales-month list error'), { status: 401 });
      }
    } catch (err) {
        console.log(err);
      throw err;
    }
  };


   
export const logAdd = async ({ shopId,logDt,totalAmt,totalEnr,totalReturnAmt,totalCnt,newFlag,beforeDaily,totalReturnCnt,ticketTotalAmt,ticketTotalEnr,ticketTotalReturnAmt,ticketTotalCnt,ticketTotalReturnCnt,}) => {
    try {
      const data = {
        shopId,
        logDt,
        totalAmt,
        totalEnr,
        totalReturnAmt,
        totalCnt,
        totalReturnCnt,
        ticketTotalAmt,
        ticketTotalEnr,
        ticketTotalReturnAmt,
        ticketTotalCnt,
        ticketTotalReturnCnt,
        newFlag,
        beforeDaily
      };
  
      const result = await apiLib.fetchStoreApi('/pos/sales-month/log-add', data);
      if (result.resultFlag) {
        return result.salesMonthLog;
      } else {
        throw Object.assign(new Error('sales-month log-add error'), { status: 401 });
      }
    } catch (err) {
        console.log(err);
      throw err;
    }
  };




import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface ShopDetailPayload {
  shopId: string;
}

interface OpenUpdatePayload {
  shopId: string;
  openState: string;
}
interface AutoOpenUpdatePayload {
  shopId: string;
  autoOpenState: string;
}
interface AutoStateUpdatePayload {
  shopId: string;
  autoOpenTime: string;
  autoEndTime: string;
  autoWorkDay: object;
}

interface ShopInfo {
  shopId: string;
  openState: string;
  autoOpenState: string;
  autoOpenTime: stirng;
  autoEndTime: string;
  autoWorkDay: object;
}

interface ShopState {
  shopInfo: ShopInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const shopInitialState: ShopState = {
  shopInfo: {},
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
  shopInfo: (state: ShopState, { payload }: PayloadAction<ShopDetailPayload>) => {
    state.actionResult = 'INFO_REQ';
    state.isLoading = true;
    state.error = null;
  },
  shopInfoSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopInfo = payload.shopInfo;
    state.actionResult = 'INFO_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopInfoFailure: (state: ShopState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'INFO_ERR';
    state.isLoading = false;
    state.error = null;
  },
  // shopDetail :(state:ShopState, { payload }: PayloadAction<ShopDetailPayload>) => {
  //   state.shopInfo.shopId = payload.shopId;
  //   state.actionResult = 'DETAIL_REQ';
  //   state.isLoading = true;
  //   state.error = null;
  // },
  // shopDetailSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
  //     state.shopInfo = payload.shopInfo;
  //     state.actionResult = 'DETAIL_OK';
  //     state.isLoading = false;
  //     state.error = null;
  //   },
  // shopDetailFailure: (state: ShopState, action: PayloadAction<string>) => {
  //     state.actionResult = 'DETAIL_ERR';
  //     state.isLoading = false;
  //     state.error = action.payload;
  //   },

  shopTotalUpdate: (state: ShopState, { payload }: PayloadAction<OpenUpdatePayload>) => {
    state.shopInfo.openState = payload.openState;
    state.shopInfo.autoOpenState = payload.autoOpenState;
    state.shopInfo.autoOpenTime = payload.autoOpenTime;
    state.shopInfo.autoEndTime = payload.autoEndTime;
    state.shopInfo.autoWorkDay = payload.autoWorkDay;
    state.actionResult = 'TOTAL_OPEN_REQ';
    state.isLoading = true;
    state.error = null;
  },
  shopTotalUpdateSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopInfo = payload.shopInfo;
    state.actionResult = 'TOTAL_OPEN_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopTotalUpdateFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'TOTAL_OPEN_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },

  shopOpenUpdate: (state: ShopState, { payload }: PayloadAction<OpenUpdatePayload>) => {
    state.shopInfo.openState = payload.openState;
    state.actionResult = 'AUTO_OPEN_REQ';
    state.isLoading = true;
    state.error = null;
  },
  shopOpenUpdateSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopInfo = payload.shopInfo;
    state.actionResult = 'OPEN_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopOpenUpdateFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'OPEN_UPDATE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  shopAutoOpenUpdate: (state: ShopState, { payload }: PayloadAction<AutoOpenUpdatePayload>) => {
    state.shopInfo.autoOpenState = payload.autoOpenState;
    state.actionResult = 'AUTO_OPEN_REQ';
    state.isLoading = true;
    state.error = null;
  },
  shopAutoOpenUpdateSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopInfo = payload.shopInfo;
    state.actionResult = 'OPEN_AUTO_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopAutoOpenUpdateFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'OPEN_AUTO_UPDATE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  shopAutoStateUpdate: (state: ShopState, { payload }: PayloadAction<AutoStateUpdatePayload>) => {
    state.shopInfo.autoOpenTime = payload.autoOpenTime;
    state.shopInfo.autoEndTime = payload.autoEndTime;
    state.shopInfo.autoWorkDay = payload.autoWorkDay;
    state.actionResult = 'OPEN_AUTO_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  shopAutoStateUpdateSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopInfo = payload.shopInfo;
    state.actionResult = 'OPEN_AUTO_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopAutoStateUpdateFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'OPEN_AUTO_UPDATE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  shopSaleClose: (state: ShopState, { payload }: PayloadAction<ShopDetailPayload>) => {
    state.actionResult = 'SALE_CLOSE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  shopSaleCloseSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.actionResult = 'SALE_CLOSE_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopSaleCloseFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'SALE_CLOSE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
};

const slice = createSlice({
  name: 'shop',
  initialState: shopInitialState,
  reducers: reducers,
});

const shopInfo = createDraftSafeSelector(
  (state: ShopState) => state.shopInfo,
  shopInfo => shopInfo,
);

// const shopDetailInfo = createDraftSafeSelector(
//     (state : ShopState) => state.shopInfo,
//     shopInfo => shopInfo,
// );

const selectStatus = createDraftSafeSelector(
  (state: ShopState) => state.actionResult,
  (state: ShopState) => state.isLoading,
  (state: ShopState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const shopSelector = {
  shopInfo: state => shopInfo(state[SHOP]),
  // shopDetail : state => shopDetailInfo(state[SHOP]),
  status: state => selectStatus(state[SHOP]),
};

export const SHOP = slice.name;
export const shopReducer = slice.reducer;
export const shopAction = slice.actions;

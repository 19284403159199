import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as AlertIcon } from '../../assets/images/order/icon-alert.svg';

import { orderSelector } from '../../modules/orderSlice';

const AlertNewOrder = ({ currentTime }) => {
  const { waitOrderList: newOrderList } = useSelector(orderSelector.filteredTypeCurrentOpenOrderList);

  const [after1MinNewOrderList, setAfter1MinNewOrderList] = useState([]); // 1분이 지난 주문대기 상태인 주문들
  const _newOrderList = useRef([]); // 주문대기 상태인 주문들 (랜더링 피하기위해 ref로 하나더 생성)

  const orderAlarm = useCallback(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: 'REQ_BELL_SOUND_PLAY' }),
      );
    }
  }, []);

  useEffect(() => {
    const filterAfter1MinNewOrderList = _newOrderList.current.filter(order =>
      dayjs(order.orderWaitDt).isBefore(dayjs().subtract(1, 'minute'), 'minute')
    );

    setAfter1MinNewOrderList(after1MinNewOrderList => filterAfter1MinNewOrderList);
    if (filterAfter1MinNewOrderList.length > 0) {
      console.log('1분 지난 주문대기들 알림', filterAfter1MinNewOrderList);
      orderAlarm();
    }
  }, [orderAlarm, currentTime]);

  useEffect(() => {
    _newOrderList.current = newOrderList;
  }, [newOrderList]);

  return (
    <AlertNewOrderBox style={after1MinNewOrderList.length > 0 && newOrderList.length > 0 ? { display: 'flex' } : { display: 'none' } }>
      <div className="text">
        <AlertIcon />
        미확인 주문이 {newOrderList.length}건 있습니다. 주문을 확인해주세요
      </div>
    </AlertNewOrderBox>
  )
};

const AlertNewOrderBox = styled.div`
  position: fixed;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 600px;
  top: 30px;
  left: 50%;
  z-index: 99;
  transform: translate(-50%, 0);
  background: rgba(0, 0, 0, 0.7);
  border-radius: 6.25rem;

  animation: fadein 3s;
  animation: blink 3.0s linear infinite;
  animation-direction: alternate;

  @keyframes blink {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    & > svg {
      margin-right: 0.75rem;
    }
    color: #FFFFFF;
  }
`;

export default React.memo(AlertNewOrder);
function getByteLengthOfUtf8String(s) {
  if (s !== undefined && s !== '') {
    let b = 0;
    let i = 0;
    let c = 0;
    for (b = i = 0; (c = s.charCodeAt(i++)); b += c >> 7 ? 2 : 1) {
      continue;
    }
    return b;
  } else {
    return 0;
  }
}

export const padSpaceStr = (str, len, isRight) => {
  let strLen = getByteLengthOfUtf8String(String(str || ''));
  let spaceStr = String('').padStart(len - strLen, ' ');
  if (isRight) {
    return spaceStr + str;
  } else {
    return str + spaceStr;
  }
};

export const open = async printerNm => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'REQ_PRINTER_OPEN' }),
    );
  }
};

export const close = async printerNm => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'REQ_PRINTER_CLOSE' }),
    );
  }
};

export const check = async printerNm => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'REQ_PRINTER_INFO' }),
    );
  }
};

export const print = async (textList) => {
  let printData = [];

  printData.push({
    type: 'beep',
    count: 2,
  });

  for (const text of textList) {
    printData.push({
      type: 'text',
      text: text.text,
      horizontal: text.horizontal ? text.horizontal : 0,
      vertical: text.vertical ? text.vertical : 0,
      bold: text.bold ? 1 : 0,
      invert: text.invert ? 1 : 0,
      underline: text.underline ? 1 : 0,
      fonttype: text.fonttype ? text.invert : 0,
      alignment: text.alignment ? text.alignment : 0,
    });
  }

  printData.push({
    type: 'cut',
  });

  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'REQ_PRINTER_PRINT', data: printData }),
    );
  }
};

import { all, fork, call, put, takeLatest, select } from 'redux-saga/effects';

import * as goodsApiLib from '../lib/goodsApi';

import { goodsAction } from './goodsSlice';
import { SHOP } from './shopSlice';

function* categoryList({ payload: brandId }) {
  try {
    const categoryList = yield call(goodsApiLib.categoryList, brandId);

    yield put(goodsAction.categoryListSuccess({ categoryList }));
  } catch (err) {
    yield put(goodsAction.categoryListFailure('Category List Error'));
  }
}

function* categoryUpdate({ payload }) {
  try {
    const { brandId } = yield select(state => state[SHOP].shopInfo);

    yield call(goodsApiLib.categoryUpdate, payload);

    const categoryList = yield call(goodsApiLib.categoryList, { brandId });

    yield put(goodsAction.categoryUpdateSuccess({ categoryList }));
  } catch (err) {
    yield put(goodsAction.categoryUpdateFailure('Category Update Error'));
  }
}

function* goodsList({ payload: { brandId, shopId, categoryId } }) {
  try {
    const goodsList = yield call(goodsApiLib.goodsList, { brandId, shopId, categoryId });

    yield put(goodsAction.goodsListSuccess({ goodsList }));
  } catch (err) {
    yield put(goodsAction.goodsListFailure('Goods List Error'));
  }
}

function* goodsDetail({ payload: { brandId, goodsId }}) {
  try {
    const { shopId } = yield select(state => state[SHOP].shopInfo);

    const goodsInfo = yield call(goodsApiLib.goodsDetail, { brandId, shopId, goodsId });

    yield put(goodsAction.goodsDetailSuccess({ goodsInfo }));
  } catch (err) {
    yield put(goodsAction.goodsDetailFailure('Goods Detail Error'));
  }
}

function* goodsEdit({ payload: { brandId, selectCategoryId, updateInfo }}) {
  try {
    const { shopId } = yield select(state => state[SHOP].shopInfo);

    yield call(goodsApiLib.goodsEdit, { shopId, updateInfo });

    const goodsList = yield call(goodsApiLib.goodsList, { brandId, categoryId: selectCategoryId });

    yield put(goodsAction.goodsEditSuccess({ goodsList }));
  } catch (err) {
    yield put(goodsAction.goodsEditFailure('Goods Edit Error'));
  }
}

function* stockUpdate({ payload: { brandId, shopId, selectCategoryId, stockId, updateInfo }}) {
  try {
    const { shopId } = yield select(state => state[SHOP].shopInfo);

    yield call(goodsApiLib.stockUpdate, { shopId, stockId, updateInfo });

    const goodsList = yield call(goodsApiLib.goodsList, { brandId, shopId, categoryId: selectCategoryId });

    yield put(goodsAction.stockUpdateSuccess({ goodsList }));
  } catch (err) {
    yield put(goodsAction.stockUpdateFailure('Stock Update Error'));
  }
}

function* changeDisplayAll({ payload: { brandId, shopId, selectCategoryId, stockList, displayYn }}) {
  try {
    yield call(goodsApiLib.changeDisplayAll, { shopId, stockList, displayYn });

    const goodsList = yield call(goodsApiLib.goodsList, { brandId, shopId, categoryId: selectCategoryId });

    yield put(goodsAction.changeDisplayAllSuccess({ goodsList }));
  } catch (err) {
    yield put(goodsAction.changeDisplayAllFailure('Change Display All Error'));
  }
}

export function* watchCategoryList() {
  yield takeLatest(goodsAction.categoryList, categoryList);
}

export function* watchCategoryUpdate() {
  yield takeLatest(goodsAction.categoryUpdate, categoryUpdate);
}

export function* watchGoodsList() {
  yield takeLatest(goodsAction.goodsList, goodsList);
}

export function* watchGoodsDetail() {
  yield takeLatest(goodsAction.goodsDetail, goodsDetail);
}

export function* watchGoodsEdit() {
  yield takeLatest(goodsAction.goodsEdit, goodsEdit);
}

export function* watchStockUpdate() {
  yield takeLatest(goodsAction.stockUpdate, stockUpdate);
}

export function* watchChangeDisplayAll() {
  yield takeLatest(goodsAction.changeDisplayAll, changeDisplayAll);
}

function* rootSaga() {
  yield all([
    fork(watchCategoryList),
    fork(watchCategoryUpdate),
    fork(watchGoodsList),
    fork(watchGoodsDetail),
    fork(watchGoodsEdit),
    fork(watchStockUpdate),
    fork(watchChangeDisplayAll),
  ]);
}

export default rootSaga;

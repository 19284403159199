import * as apiLib from './api';

export const list = async ({ shopId, orderStartDt, orderEndDt }) => {
  try {
    const data = {
      shopId,
      orderStartDt,
      limit: 200,
      LastEvaluatedKey: null,
      orderEndDt,
    };
    let searchFlag = true;

    let order = [];
    // const result    console.log("order",order);

    while (searchFlag) {
      const result = await apiLib.fetchStoreApi('/pos/order/list', data);
      order = order.concat(result.orderList);
      if (result.LastEvaluatedKey === undefined) {
        searchFlag = false;
      }
      data.LastEvaluatedKey = result.LastEvaluatedKey;
    }

    return order;

    // if (order.resultFlag) {
    // } else {
    //   throw Object.assign(new Error('order error'), { status: 401 });
    // }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const openList = async ({ shopId, orderStartDt, orderEndDt }) => {
  try {
    const data = {
      shopId,
      orderStartDt,
      orderEndDt,
    };

    const result = await apiLib.fetchStoreApi('/pos/order/open-list', data);
    if (result.resultFlag) {
      return result.orderList;
    } else {
      throw Object.assign(new Error('order error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const update = async ({ orderId, updateInfo }) => {
  try {
    const data = {
      orderId,
      updateInfo,
    };

    const result = await apiLib.fetchStoreApi('/pos/order/update', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('order error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const regOrder = async ({ shopId, orderId, customerId, takeType, takeTime, takeZone }) => {
  try {
    const data = {
      shopId,
      orderId,
      customerId,
      takeType,
      takeTime,
      takeZone,
    };

    const result = await apiLib.fetchStoreApi('/pos/order/reg', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('order error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const takeOrder = async ({ shopId, orderId, customerId, takeType, takeZone }) => {
  try {
    const data = {
      shopId,
      orderId,
      customerId,
      takeType,
      takeZone,
    };

    const result = await apiLib.fetchStoreApi('/pos/order/take', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('order error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const takeOrderRobot = async ({ shopId, orderId, customerId, takeType, takeZone, robotName, robotPwd }) => {
  try {
    const data = {
      shopId,
      orderId,
      customerId,
      takeType,
      takeZone,
      robotName,
      robotPwd,
    };

    const result = await apiLib.fetchStoreApi('/pos/order/take', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('order error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saleOrderList = async ({ shopId, orderIdList, robotYn }) => {
  try {
    const data = {
      shopId,
      orderIdList,
      robotYn,
    };

    const result = await apiLib.fetchStoreApi('/pos/order/sale-list', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('order error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const cancelOrderList = async ({ shopId, orderIdList }) => {
  try {
    const data = {
      shopId,
      orderIdList,
    };

    const result = await apiLib.fetchStoreApi('/pos/order/cancel-list', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('order error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const returnOrder = async ({ shopId, orderId, billType }) => {
  try {
    const data = {
      shopId,
      orderId,
      billType,
    };

    const result = await apiLib.fetchStoreApi('/pos/order/return', data);
    if (result.resultFlag) {
      return result.newOrder;
    } else {
      throw Object.assign(new Error('order error'), { status: 401, errorType: result.errorType });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

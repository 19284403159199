import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { agentAction } from './agentSlice';

import * as agentApi from '../lib/agentApi';

function* pairing({ payload }) {
  try {
    const pairingInfo = yield call(agentApi.pairing, payload);
    yield put(agentAction.pairingSuccess({ pairingInfo }));
  } catch (err) {
    yield put(agentAction.pairingFailure('Pairing Error'));
  }
}

function* licenseCheck({ payload }) {
  try {
    const deviceToken = yield call(agentApi.licenseCheck, payload);
    yield put(
      agentAction.licenseCheckSuccess({ licenseInfo: { deviceToken } })
    );
  } catch (err) {
    yield put(agentAction.licenseCheckFailure('License Check Error'));
  }
}

function* deviceManage({ payload }) {
  try {
    const deviceInfo = yield call(agentApi.deviceManage, payload);
    yield put(agentAction.deviceManageSuccess({ deviceInfo }));
  } catch (err) {
    console.log(err);
    yield put(agentAction.deviceManageFailure('Device Manage Error'));
  }
}

function* scrshotUpload({ payload }) {
  try {
    yield call(agentApi.scrshotUpload, payload);
    yield put(agentAction.scrshotUploadSuccess());
  } catch (err) {
    console.log(err);
    yield put(agentAction.scrshotUploadFailure('Scrshop Upload Error'));
  }
}

export function* watchPairing() {
  yield takeLatest(agentAction.pairing, pairing);
}

export function* watchLicenseCheck() {
  yield takeLatest(agentAction.licenseCheck, licenseCheck);
}

export function* watchDeviceManage() {
  yield takeLatest(agentAction.deviceManage, deviceManage);
}

export function* watchScrshotUpload() {
  yield takeLatest(agentAction.scrshotUpload, scrshotUpload);
}

function* rootSaga() {
  yield all([
    fork(watchPairing),
    fork(watchLicenseCheck),
    fork(watchDeviceManage),
    fork(watchScrshotUpload),
  ]);
}

export default rootSaga;
